import React from 'react';
import {
  Alert, AlertIcon,
  VStack,
  chakra
} from '@chakra-ui/react';
import LycanFormattedMessage from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage';
import messages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/ReservationErrors/messages';
import { Button } from '@rentivo/gatsby-core/index';
import { routePathKeys } from '@rentivo/gatsby-core/src/components/navigation/RouteLink/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-regular-svg-icons/faLongArrowLeft';
import { FormattedMessage } from 'react-intl';
import RouteLink from '@rentivo/gatsby-core/src/components/navigation/RouteLink';
import PropertyLink from '@rentivo/gatsby-core/src/components/property/PropertyLink';
import ConditionalWrapper from '@rentivo/gatsby-core/src/components/generic/ConditionalWrapper';

const specialErrors = {
  'UNKNOWN_PROPERTY': {
    buttonLabel: messages.backToProperty,
    buttonLocation: 'property'
  }
};

const ReservationErrorsComponent = ({reservationError}) => {
  if(!reservationError || !reservationError.length) return null;

  if(Array.isArray(reservationError)) {
    return (
      <VStack spacing={4} align="stretch" mt={4} mb={4}>
        {reservationError.map((error, i) => (
          <Alert status="error" key={i}>
            <AlertIcon />
            {(error.type) ? <LycanFormattedMessage message={`COMMERCE_${error.type}`} fallback={error.message}/> : <LycanFormattedMessage message={error.message} fallback={error.message}/>}
            {(specialErrors[error.type] && specialErrors[error.type].buttonLabel) && (
              <chakra.div>
                {(specialErrors[error.type].buttonLabel) && (
                  <ConditionalWrapper
                    condition={(specialErrors[error.type].buttonLocation === 'property')}
                    wrapper={c => <PropertyLink forceNewTab={false}>{c}</PropertyLink>}
                    defaultWrapper={c => <RouteLink route={routePathKeys.ROUTE_BASE_SEARCH}>{c}</RouteLink>}
                  >
                    <Button size="small" ml={4}><FontAwesomeIcon icon={faLongArrowLeft} style={{marginRight: 7}}/> <FormattedMessage {...specialErrors[error.type].buttonLabel}/></Button>
                  </ConditionalWrapper>
                )}
              </chakra.div>
            )}
          </Alert>
        ))}
      </VStack>
    );
  } else {
    return (
      <Alert status="error">
        <AlertIcon />
        {reservationError}
      </Alert>
    )
  }
};

export default ReservationErrorsComponent;
