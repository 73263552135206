import { defineMessages } from 'react-intl';

export const scope = 'containers.CommerceProvider.SuccessInstantBook';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Congratulations, you\'re booking has been confirmed',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Payment has been taken and your booking has been confirmed',
  },
  longDescription: {
    id: `${scope}.longDescription`,
    defaultMessage: 'You will receive an email confirmation with all the details of your booking. Thank you for booking with us!',
  }
});
