import CheckoutFlow from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/CheckoutFlow/component';
import PropTypes from 'prop-types';

const CheckoutWrapper = ({children}) => {
  return (
    <>
      {children}
    </>
  );
};

CheckoutFlow.propTypes = {
  wrapper: PropTypes.func.isRequired
};

CheckoutFlow.defaultProps = {
  wrapper: CheckoutWrapper
};

export default CheckoutFlow;
