import moment from 'moment';

export const getMonthsInYear = (startFromJan = true) => {
  const months = [];
  const dateStart = (startFromJan) ? moment().startOf('year') : moment();
  const dateEnd = (startFromJan) ? moment().startOf('year').add(12, 'month') : moment().add(12, 'month');
  while (dateEnd.diff(dateStart, 'months') > 0) {
    const MMM = dateStart.format('MMM');
    const MM = dateStart.format('MM');
    months.push({
      name: `${MM} — ${MMM}`,
      value: MM
    });
    dateStart.add(1, 'month');
  }
  return months
};

export const getYearsFromNow = (noOfYears = 10) => {
  const years = [];
  const dateStart = moment();
  const dateEnd = moment().add(noOfYears, 'y');
  while (dateEnd.diff(dateStart, 'years') >= 0) {
    years.push(dateStart.format('YYYY'));
    dateStart.add(1, 'year');
  }
  return years
};
