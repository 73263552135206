import React from 'react';
import { LocaleLink } from '@rentivo/gatsby-core/index';

const RouteLinkComponent = ({route, routePaths, children, to, ...rest}) => {
  return (
    <LocaleLink to={`/${routePaths[route]}/${to ? to.replace(/^\/|\/$/g, '') : ``}/`} {...rest}>{children}</LocaleLink>
  )
};

export default RouteLinkComponent;
