import CustomerInfoForm from '@rentivo/gatsby-core/src/components/forms/CustomerInfoForm/component';
import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { makeSelectCountries } from '@rentivo/gatsby-core/src/selectors/countries';
import { selectCheckoutInfoPaymentRequiredConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';

const mapStateToProps = createSelector(
  makeSelectCountries,
  selectCheckoutInfoPaymentRequiredConfig,
  (countries, phoneRequired) => ({
    countries,
    phoneRequired
  })
);

const enhance = compose(
  connect(mapStateToProps)
);

CustomerInfoForm.defaultProps = {
  loading: false,
  defaultValues: {}
};

export default enhance(CustomerInfoForm);
