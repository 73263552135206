import { chakra } from '@chakra-ui/react';

export const StyledReservationBreakdownWell = chakra('div', {
  baseStyle: {
    bg: 'gray.100',
    px: 4,
    py: 3,
    boxShadow: "lg",
    mb: 4,
    borderRadius: "md"
  }
});

export const StyledReservationBreakdownBlocks = chakra('div', {
  baseStyle: {
    display: { base: 'block', lg: 'flex' },
    alignItems: 'center',
    '> div': {
      '&:first-child': {
        w: { base: '100%', lg: '40%' }
      },
      '&:last-child': {
        w: { base: '100%', lg: '60%' }
      }
    },
    '.day-block': {
      bg: 'white'
    }
  }
});
