import React from 'react';
import Checkboxes from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/Checkboxes/component';
import { compose } from 'redux';
import withCart from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/withCart';

const enhance = compose(
  withCart()
);

export default enhance(Checkboxes);
