import React from 'react';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import PropertyCancellation from '@rentivo/gatsby-core/src/components/property/PropertyCancellation/component';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import {
  selectPropertyCancellationPolicy,
  selectPropertyCancellations
} from '@rentivo/gatsby-core/src/selectors/property';
import {
  selectSiteCancellationPoliciesConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { connect } from 'react-redux';
import {
  selectPricingEndDate,
  selectPricingStartDate
} from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors';
import dayjs from 'dayjs';
import { DB_DATE_FORMAT } from '@rentivo/gatsby-core/src/constants/dates';
import { sanitizePolicyItems } from '@rentivo/gatsby-core/src/components/property/PropertyCancellation/utils';

const defaultProps = {
  showTitle: true,
  title: null,
  wrapper: ({children}) => children
};

const propertySelector = createSelector(
  selectPropertyCancellationPolicy,
  selectPropertyCancellations,
  (policyKey, cancellationsArray) => ({
    policyKey,
    cancellationsArray
  })
);

const mapStateToProps = (_state, { policyKey, cancellationsArray }) => createSelector(
  selectSiteCancellationPoliciesConfig,
  selectPricingStartDate,
  selectPricingEndDate,
  (policiesConfig, startDate, endDate) => {

    const daysToCheckin = (startDate && endDate) ? dayjs(startDate, DB_DATE_FORMAT).diff(dayjs(), 'day') : null;
    
    let policyItems = (policiesConfig[policyKey] && policiesConfig[policyKey].length) ? sanitizePolicyItems(policiesConfig[policyKey], daysToCheckin) : null;

    if(cancellationsArray && cancellationsArray.length) {
      policyItems = sanitizePolicyItems(cancellationsArray, daysToCheckin);
    }

    return {
      policyItems,
      startDate,
      daysToCheckin
    }
  }
);

const enhance = compose(
  withProperty(propertySelector),
  connect(mapStateToProps)
);

PropertyCancellation.defaultProps = defaultProps;

export default enhance(PropertyCancellation);
