import React from 'react';
import { Container } from '@rentivo/gatsby-core';
import PricingWidget from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingWidget';
import PricingProvider from '@rentivo/gatsby-core/src/containers/PricingProvider';
import {
  StyledStepLayout,
  StyledStepLayoutContent,
  StyledStepsHeader
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/StepLayout/styles';
import { Grid, GridItem } from '@chakra-ui/react';

const StepLayoutComponent = ({ isMobile, children, valid, showStepsHeader }) => {
  return (
    <StyledStepLayout>
      <Container>
        {showStepsHeader && <StyledStepsHeader/>}

        <Grid templateColumns="repeat(24, 1fr)" gap={{base: 0, md: 6}}>
          <GridItem
            colSpan={{ base: 24, lg: (valid) ? 16 : 24, xl: (valid) ? 17 : 24}}
            order={isMobile ? 2 : 1}
          >
            <StyledStepLayoutContent>
              {children}
            </StyledStepLayoutContent>
          </GridItem>
          {valid && (
            <GridItem
              colSpan={{ base: 24, lg: 8, xl: 7}}
              order={isMobile ? 1 : 2}
            >
              <PricingProvider>
                <PricingWidget/>
              </PricingProvider>
            </GridItem>
          )}
        </Grid>

      </Container>
    </StyledStepLayout>
  )
};

StepLayoutComponent.defaultProps = {
  valid: true,
  showStepsHeader: true
};

export default StepLayoutComponent;
