import { useStyleConfig, useToken } from '@chakra-ui/react';

export const usePaymentInputStyles = () => {
  const { field: { borderColor, bg, color, fontSize, borderRadius, border, h, pl, pr, boxShadow}} = useStyleConfig('Input', { variant: 'outline', size: 'md'});
  const [borderColorToken, bgToken, colorToken] = useToken("colors", [borderColor, bg, color]);
  const [fontSizeToken] = useToken("typography.fontSizes", [fontSize]);
  const [hToken, plToken, prToken] = useToken("sizes", [h, pl, pr]);
  const [borderRadiusToken] = useToken("radii", [borderRadius]);
  const [boxShadowToken] = useToken("shadows", [boxShadow]);
  return `height:${hToken};border-radius: ${borderRadiusToken};border: ${border};border-color:${borderColorToken};${boxShadow !== undefined && boxShadowToken ? `boxShadow:${boxShadowToken};` : ``}box-sizing: border-box;font-variant: tabular-nums;list-style: none;font-feature-settings: 'tnum';position: relative;display: inline-block;width: 100%;min-width: 0;padding-left:${plToken};padding-right:${prToken};color:${colorToken};font-size:1rem;line-height:${hToken};background-color:${bgToken};background-image: none;`;
};
