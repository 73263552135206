import { defineMessages } from 'react-intl';

export const scope = 'containers.CommerceProvider.Confirm.PaymentCards';

export default defineMessages({
  acceptCardTooltip: {
    id: `${scope}.acceptCardTooltip`,
    defaultMessage: 'We accept {card}',
  },
});
