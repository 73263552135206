import React from 'react';
import ReservationErrors from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/ReservationErrors/component';
import { compose } from 'redux';
import withCart from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/withCart';

const enhance = compose(
  withCart()
);

export default enhance(ReservationErrors);
