import React from 'react';
import CartBreakdown from '@rentivo/gatsby-core/src/components/checkout/CartBreakdown';

const CartBreakdownComponent = (props) => {
  return (
    <CartBreakdown {...props}/>
  )
};

export default CartBreakdownComponent;
