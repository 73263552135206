import React from 'react';
import Success from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Success/component';
import { compose } from 'redux';
import {
  CHECKOUT_STEP_SUCCESS_PATH
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/constants';
import { createSelector } from 'reselect';
import {
  makeSelectCommercePropertyAndPricingInData,
  selectCommerceReservationType
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/selectors';
import { connect } from 'react-redux';

const mapStateToProps = createSelector(
  selectCommerceReservationType,
  makeSelectCommercePropertyAndPricingInData,
  (reservationType, { propertyInData }) => {
    return {
      reservationType,
      propertyInData
    }
  }
);

const enhance = compose(
  connect(mapStateToProps)
);

Success.defaultProps = {
  path: CHECKOUT_STEP_SUCCESS_PATH,
  success: true
};

export default enhance(Success);
