import React from 'react';
import valid from 'card-validator';
import { Input, Select, Alert, AlertIcon } from '@chakra-ui/react';
import { Button } from '@rentivo/gatsby-core/index';
import FormItem from '@rentivo/gatsby-core/src/components/forms/FormItem';
import { Row, Col } from '@rentivo/gatsby-core/src/components/ui/Row';
import { FormattedMessage, useIntl } from 'react-intl';
import customerInfoMessages from '@rentivo/gatsby-core/src/components/forms/CustomerInfoForm/messages';
import messages from '@rentivo/gatsby-core/src/components/forms/PaymentForm/messages';
import {
  StyledPaymentForm,
  StyledPaymentFormAddressText,
  StyledPaymentFormBillingTitle,
  StyledPaymentFormGroup, StyledPaymentFormSpreedlyErrors
} from '@rentivo/gatsby-core/src/components/forms/PaymentForm/styles';
import { getYearsFromNow, getMonthsInYear } from '@rentivo/gatsby-core/src/utils/dates';
import { useLycanFormattedMessage } from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage';

const years = getYearsFromNow(20);
const months = getMonthsInYear();

const PaymentFormComponent = ({
    form,
    loading,
    countries = [],
    clearForm,
    defaultValues,
    info,
    useDifferentBillingAddress,
    handleToggleBillingAddress,
    hasSpreedlyErrors,
    spreedlyErrors,
    cardsComponent: CardsComponent,
    checkboxesComponent: CheckboxesComponent
}) => {
  const { formatMessage } = useIntl();
  const formatLycanMessage = useLycanFormattedMessage();
  const countryOptions = countries.map(c => <option key={c.code} value={c.code}>{formatLycanMessage(c.name, c.name)}</option>);

  const formRows = {
    spacing: 4,
    mb: 4
  };

  const doubleFormCols = {
    colSpan: {
      base: 24,
      lg: 12
    },
    mb: {
      base: 3,
      lg: 0
    }
  };

  const monthOptions = months.map(m => <option key={m.value} value={m.value}>{m.name}</option>);
  const yearOptions = years.map(y => <option key={`${y}`} value={`${y}`}>{`${y}`}</option>);

  const prevAddress = (info && info.addressLine1 && info.city && info.zip && info.country) ?
      `${info.addressLine1}, ${info.city}, ${info.zip}, ${info.country}` : '';

  return (

    <StyledPaymentForm className="payment-form" id="payment-form">

        <StyledPaymentFormGroup>
          <StyledPaymentFormBillingTitle as="h3">
            <span><FormattedMessage {...messages.billingAddressTitle}/></span>
            <Button onClick={handleToggleBillingAddress}>
              {!useDifferentBillingAddress ? <FormattedMessage {...messages.differentBillingAddressButtonLabel}/> : <FormattedMessage {...messages.sameBillingAddressButtonLabel}/>}
            </Button>
          </StyledPaymentFormBillingTitle>

          {useDifferentBillingAddress ? (
            <>
              <Row {...formRows}>
                <Col {...doubleFormCols}>

                  <FormItem
                    name="addressLine1"
                    label={formatMessage(customerInfoMessages.inputAddressLineOneLabel)}
                    rules={{
                      required: true
                    }}
                    render={props => (
                      <Input
                        type="text"
                        placeholder={formatMessage(customerInfoMessages.inputAddressLineOneLabel)}
                        {...props}
                      />
                    )}
                  />

                </Col>
                <Col {...doubleFormCols}>

                  <FormItem
                    name="addressLine2"
                    label={formatMessage(customerInfoMessages.inputAddressLineTwoLabel)}
                    render={props => (
                      <Input
                        type="text"
                        placeholder={formatMessage(customerInfoMessages.inputAddressLineTwoLabel)}
                        {...props}
                      />
                    )}
                  />

                </Col>
              </Row>

              <Row {...formRows}>
                <Col {...doubleFormCols}>

                  <FormItem
                    name="city"
                    label={formatMessage(customerInfoMessages.inputCityLabel)}
                    rules={{
                      required: true
                    }}
                    render={props => (
                      <Input
                        type="text"
                        placeholder={formatMessage(customerInfoMessages.inputCityLabel)}
                        {...props}
                      />
                    )}
                  />

                </Col>
                <Col {...doubleFormCols}>

                  <FormItem
                    name="state"
                    label={formatMessage(customerInfoMessages.inputStateLabel)}
                    render={props => (
                      <Input
                        type="text"
                        placeholder={formatMessage(customerInfoMessages.inputStateLabel)}
                        {...props}
                      />
                    )}
                  />

                </Col>
              </Row>

              <Row {...formRows}>
                <Col {...doubleFormCols}>

                  <FormItem
                    name="zip"
                    label={formatMessage(customerInfoMessages.inputZipLabel)}
                    rules={{
                      required: true
                    }}
                    render={props => (
                      <Input
                        type="text"
                        placeholder={formatMessage(customerInfoMessages.inputZipLabel)}
                        {...props}
                      />
                    )}
                  />

                </Col>
                <Col {...doubleFormCols}>

                  <FormItem
                    name="country"
                    label={formatMessage(customerInfoMessages.inputCountryLabel)}
                    rules={{
                      required: true
                    }}
                    render={props => (
                      <Select
                        {...props}
                        placeholder={formatMessage(customerInfoMessages.inputCountryLabel)}
                      >
                        {countryOptions}
                      </Select>
                    )}
                  />

                </Col>
              </Row>
            </>
          ) : (
            <StyledPaymentFormAddressText>
              {prevAddress}
            </StyledPaymentFormAddressText>
          )}
        </StyledPaymentFormGroup>

        <StyledPaymentFormGroup>
          <StyledPaymentFormBillingTitle>
            <span><FormattedMessage {...messages.cardDetailsTitle}/></span>
            {CardsComponent && <CardsComponent/>}
          </StyledPaymentFormBillingTitle>

          {hasSpreedlyErrors && (
            <StyledPaymentFormSpreedlyErrors id="spreedly-errors">
              {spreedlyErrors.map((errorMsg, i) => (
                <Alert key={i} status="error">
                  <AlertIcon />
                  {errorMsg}
                </Alert>
              ))}
            </StyledPaymentFormSpreedlyErrors>
          )}

          <FormItem
            name="fullName"
            mb={4}
            label={formatMessage(messages.inputFullNameLabel)}
            rules={{
              required: true
            }}
            render={props => (
              <Input
                type="text"
                placeholder={formatMessage(messages.inputFullNameLabel)}
                {...props}
                rules={{
                  validate: value => (value.trim().indexOf(' ') !== -1) || formatMessage(messages.inputExpiryMonthErrorValid)
                }}
              />
            )}
          />

          <Row {...formRows} id="spreedly-form">
            <Col colSpan={{base: 24, md: 16}} mb={{base: 4, md: 0}}>
              <FormItem
                name="spreedlyNumber"
                label={formatMessage(messages.inputCardNumberLabel)}
                render={() => (
                  <div id="spreedly-number"/>
                )}
              />
            </Col>
            <Col colSpan={{base: 24, md: 8}}>
              <FormItem
                name="spreedlyCvv"
                label={formatMessage(messages.inputCVCLabel)}
                render={() => (
                  <div id="spreedly-cvv"/>
                )}
              />
            </Col>
          </Row>

          <Row {...formRows}>
            <Col colSpan={{base: 24, md: 12}} mb={{base: 4, md: 0}}>

              <FormItem
                name="expiryMonth"
                label={formatMessage(messages.inputExpiryMonthLabel)}
                rules={{
                  validate: value => valid.expirationMonth(value).isPotentiallyValid || formatMessage(messages.inputExpiryMonthErrorValid)
                }}
                render={props => (
                  <Select
                    {...props}
                  >
                    <option key="m0">{formatMessage(messages.inputExpiryMonthLabel)}</option>
                    {monthOptions}
                  </Select>
                )}
              />

            </Col>
            <Col colSpan={{base: 24, md: 12}}>

              <FormItem
                name="expiryYear"
                label={formatMessage(messages.inputExpiryYearLabel)}
                rules={{
                  validate: value => valid.expirationYear(value).isPotentiallyValid || formatMessage(messages.inputExpiryYearErrorRequired)
                }}
                render={props => (
                  <Select
                    {...props}
                  >
                    <option key="y0">{formatMessage(messages.inputExpiryYearLabel)}</option>
                    {yearOptions}
                  </Select>
                )}
              />

            </Col>
          </Row>

          {CheckboxesComponent && <CheckboxesComponent/>}
        </StyledPaymentFormGroup>
    </StyledPaymentForm>
  );

};

export default PaymentFormComponent;
