import React, { useEffect } from 'react';
import { PropertyProvider } from '@rentivo/gatsby-core/src/context/PropertyContext';

const PricingProviderComponent = ({children, dataConfig, setDefaultValues, property, propertyId, propertyName, providedPricing, inCheckout}) => {
  useEffect(() => {
    // componentDidMount
    setDefaultValues({payload: {}, dataConfig, propertyId, propertyName, property, providedPricing, inCheckout});
  }, [property, inCheckout]);

  return (
    <PropertyProvider property={property}>
      {children}
    </PropertyProvider>
  );
};

export default PricingProviderComponent;
