import { chakra } from '@chakra-ui/react';

export const StyledCartBreakdownTable = chakra('div', {
  baseStyle: {
    boxShadow: "lg",
    mb: 4,
    borderRadius: "md",
    overflowX: "auto",
    "table": {
      borderRadius: "md",
      fontSize: { base: "sm", md: "md" },
      fontWeight: "medium",
      border: "1px solid",
      borderColor: "gray.100",
      borderCollapse: "collapse",
      width: "100%",
      maxWidth: "100%",
      whiteSpace: "nowrap",
      backgroundColor: "white"
    },
    "table .product": {
      maxWidth: "300px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    "table td, table th": {
      textOverflow: "ellipsis",
      textAlign: "left",
      px: { base: 1, md: 2 },
      py: { base: '1px', md: 1 }
    },
    "table td": {
      borderRight: "1px solid",
      borderColor: "gray.100",
      fontSize: { base: "sm", md: "md" },
      small: {
        svg: { marginRight: "2px", "&:last-child": { marginLeft: "10px" } }
      }
    },
    "table thead th": { background: "gray.100" },
    "table thead th:nth-child(odd)": { background: "gray.200" },
    "table tfoot th": { borderTop: "1px solid", borderColor: "gray.100", fontSize: { base: "sm", md: "md" } },
    "table tr:nth-child(even)": { background: "gray.100" }
  }
});
