import React from 'react';
import { createSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  selectCommerceCurrentStep,
  selectCommerceSteps
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/selectors';
import { startChangeStep } from '@rentivo/gatsby-core/src/containers/CommerceProvider/actions';
import {
  selectPricingIsFetching
} from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors';
import { sanitizeSteps } from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/StepsHeader/utils';

const withSteps = WrappedComponent => {

  const withStepsComponent = (props) => {
    return <WrappedComponent {...props} />;
  };

  const mapStateToProps = createSelector(
    selectCommerceSteps,
    selectCommerceCurrentStep,
    selectPricingIsFetching,
    (steps, currentStep, isPricingLoading) => ({
      steps,
      currentStep,
      isPricingLoading,
      hasPrevStep: (currentStep > 0),
      hasNextStep: ((currentStep + 1) < steps.length)
    })
  );

  const mapDispatchToProps = dispatch => ({
    nextStep: () => dispatch(startChangeStep('next')),
    prevStep: () => dispatch(startChangeStep('prev'))
  });

  const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps)
  );

  return enhance(withStepsComponent);

};

export default withSteps;
