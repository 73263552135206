import React from 'react';
import CheckoutComponent from '@rentivo/gatsby-theme-barcelona/src/templates/Checkout/component';
import { useMenusAndBlocksData } from '@rentivo/gatsby-theme-barcelona/src/hooks/usePageData';

const Checkout = (props) => {
  const menusAndBlocks = useMenusAndBlocksData(props);
  return (
    <CheckoutComponent {...menusAndBlocks} {...props}/>
  );
};

export default Checkout;
