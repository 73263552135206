import { chakra } from '@chakra-ui/react';

export const StyledDatesReview = chakra('div');

export const StyledDatesReviewDays = chakra('div', {
  baseStyle: {
    display: {base: 'block', md: 'flex' },
    flexDirection: 'row',
    mb: 2
  }
});

export const StyledDatesReviewDay = chakra('div', {
  baseStyle: {
    w: { base: '100%', md: '50%' },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    _firstChild: {
      mb:3
    }
  }
});

export const StyledDatesReviewDayBlock = chakra('div', {
  baseStyle: {
    w: '75px',
    h: '75px',
    textAlign: 'center',
    display: 'flex',
    bg: 'gray.200',
    alignItems: 'center',
    borderRadius: 'md',
    position: 'relative',
    _after: {
      content: '""',
      position: 'absolute',
      top: 0,
      h: '10px',
      left: 0,
      w: '100%',
      borderTopRadius: 'md',
      bg: 'secondary.500'
    }
  }
});

export const StyledDatesReviewDayBlockItem = chakra('div', {
  baseStyle: {
    mt: '10px',
    w: '100%'
  }
});

export const StyledDatesReviewDayBlockItemLabel = chakra('label', {
  baseStyle: {
    mb: 0,
    display: 'block',
    w: '100%',
    fontSize: '18px',
    textAlign: 'center',
    fontWeight: 'semibold',
    lineHeight: 'normal',
    textTransform: 'uppercase'
  }
});

export const StyledDatesReviewDayLabel = chakra('label', {
  baseStyle: {
    ml: 3,
    fontSize: 'md',
    fontWeight: 'semibold'
  }
});

export const StyledTime = chakra('div', {
  baseStyle: {
    color: 'textLight',
    fontSize: 'sm'
  }
});
