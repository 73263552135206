import React from 'react';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import PropertyLink from '@rentivo/gatsby-core/src/components/property/PropertyLink/component';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import { selectProperty, selectPropertySlug } from '@rentivo/gatsby-core/src/selectors/property';
import {
  selectPropertyPricingDataConfig,
  selectRoutePathsConfig,
  selectSearchResultsOpenInNewTabConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { connect } from 'react-redux';
import { selectPropertyPath, selectTarget } from '@rentivo/gatsby-core/src/selectors/paths';
import {
  makeSelectSearchFilters,
  selectSearchFilters
} from '@rentivo/gatsby-core/src/containers/SearchProvider/selectors';
import { selectFiltersForPricingUrlParams } from '@rentivo/gatsby-core/src/components/property/PropertyLink/selectors';
import { createDeepEqualSelector } from '@rentivo/gatsby-core/src/selectors/utils';

const propertySelector = createSelector(
  selectProperty,
  selectPropertySlug,
  (property, slug) => ({
    slug,
    property
  })
);

const mapStateToProps = (state, { slug, inSearch }) => {
  let selectors = [
    selectRoutePathsConfig,
    selectSearchResultsOpenInNewTabConfig
  ];

  let combiner = (routePaths, openInNewTab) => ({
    routePaths,
    target: selectTarget(openInNewTab),
    path: selectPropertyPath(slug, routePaths),
    queryParamsString: null
  });

  if(inSearch) {
    selectors = [
      ...selectors,
      selectSearchFilters,
      selectPropertyPricingDataConfig,
    ];

    combiner = (routePaths, openInNewTab, filters, pricingDataConfig) => ({
      routePaths,
      target: selectTarget(openInNewTab),
      openInNewTab,
      path: selectPropertyPath(slug, routePaths),
      queryParamsString: selectFiltersForPricingUrlParams(filters, pricingDataConfig)
    });
  }

  return createDeepEqualSelector([...selectors], combiner);
};

const enhance = compose(
  withProperty(propertySelector),
  connect(mapStateToProps),
);

export default enhance(PropertyLink);
