import React from 'react';
import { Grid, GridItem, Box } from '@chakra-ui/react';

const RowContext = React.createContext({});

export const Row = ({children, spacing = 3, ...rest}) => {
  return (
    <Grid
      templateColumns="repeat(24, 1fr)"
      mx={spacing === 0 || spacing === '0px' ? 0 : `-${spacing}`}
      {...rest}
    >
      <RowContext.Provider value={{spacing, colLength: children.length}}>
        {children}
      </RowContext.Provider>
    </Grid>
  )
};

export const Col = ({children, colSpan = { base: 24 }, innerHeight, ...rest}) => {
  return (
    <RowContext.Consumer>
      {({spacing}) => (
        <GridItem
          colSpan={colSpan}
          {...rest}
        >
          <Box px={spacing} h={innerHeight}>
            {children}
          </Box>
        </GridItem>
      )}
    </RowContext.Consumer>
  )
};
