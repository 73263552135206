import messages from '@rentivo/gatsby-core/src/components/property/PropertyCancellation/messages';
import dayjs from 'dayjs';
import { DB_DATE_FORMAT, FORMAT_DATE_TINY_OPTIONS } from '@rentivo/gatsby-core/src/constants/dates';

export const sanitizePolicyItems = (policyItems, daysToCheckin) => {
  if(!policyItems.length) return null;

  let activeIndex = 0;
  for(let i = 0; i < policyItems.length; i++) {
    policyItems[i].isActive = false;
    policyItems[i].percentageRetained = parseFloat(policyItems[i].percentageRetained.toFixed(1)); // Sanitize percentage
    if(daysToCheckin <= policyItems[i].daysBeforeArrival) {
      activeIndex = i + 1;
    }

    // Sanitize last item
    if((i + 1) === policyItems.length) {
      if(policyItems[i].percentageRetained === 100 && policyItems[i].daysBeforeArrival > 0) {
        // If last item doesn't meet the checkin day (0) but has 100 retained (0 refund), then set to 0
        policyItems[i].daysBeforeArrival = 0;
      } else if(policyItems[i].daysBeforeArrival > 0) {
        // If last item does not have 100 or 0, add one, to always have a no refund scenario
        policyItems[i + 1] = {
          daysBeforeArrival: 0,
          percentageRetained: 100
        }
      }
    }
  }

  if((daysToCheckin || daysToCheckin === 0)) {
    if(policyItems[activeIndex]) {
      policyItems[activeIndex].isActive = true;
    } else if(policyItems[policyItems.length - 1]) {
      policyItems[policyItems.length - 1].isActive = true;
    }
  }


  return policyItems.sort((a, b) => b.daysBeforeArrival - a.daysBeforeArrival);
};

export const getRefundMessage = (formatMessage, policyItem) => {
  const refundedPercentage = (100 - policyItem.percentageRetained);

  let refund;
  switch (refundedPercentage) {
    case 100:
      refund = formatMessage(messages.freeCancellation);
      break;
    case 0:
      refund = formatMessage(messages.noRefund);
      break;
    default:
      refund = formatMessage(messages.someRefund, { refundedPercentage });
  }

  return { refund, refundedPercentage };
};

export const getDateFromStartDate = (formatDate, policyItem, startDate, format = FORMAT_DATE_TINY_OPTIONS) => {
  let date = null;
  if(startDate) {
    date = dayjs(startDate, DB_DATE_FORMAT).subtract(policyItem.daysBeforeArrival, 'day').format(DB_DATE_FORMAT);
    date = formatDate(date, format);
  }

  return date;
};
