import isEmpty from 'lodash/isEmpty';
import { window } from 'browser-monads';

export const selectFiltersForPricingUrlParams = (filters, pricingDataConfig) => {
  const currentUrlSearchParams = new URLSearchParams(window.location.search);
  const urlSearchParams = new URLSearchParams();
  if(!filters || filters.length === 0) return null;
  filters.forEach(({id, urlParam, value}) => {
    if(id && (!isEmpty(value) || value > 0)) {
      if(id === 'availability' || id === 'guests' || id === 'adults' || id === 'children' || id === 'infants' || id === 'pets') {
        if(id === 'availability' && value.length === 2 && pricingDataConfig['startDate'] && pricingDataConfig['endDate']) {
          urlSearchParams.set(pricingDataConfig['startDate'].urlParam, value[0]);
          urlSearchParams.set(pricingDataConfig['endDate'].urlParam, value[1]);
          urlSearchParams.set(urlParam, currentUrlSearchParams.get(urlParam));
        }

        if(id === 'guests' && pricingDataConfig['guests']) urlSearchParams.set(pricingDataConfig['guests'].urlParam, value);
        if(id === 'adults') urlSearchParams.set(pricingDataConfig['adults'].urlParam, value);
        if(id === 'children') urlSearchParams.set(pricingDataConfig['children'].urlParam, value);
        if(id === 'infants') urlSearchParams.set(pricingDataConfig['infants'].urlParam, value);
        if(id === 'pets') urlSearchParams.set(pricingDataConfig['pets'].urlParam, value);
      } else {
        const existingValue = currentUrlSearchParams.get(urlParam);
        if(existingValue) {
          urlSearchParams.set(urlParam, existingValue);
        }
      }
    }
  });

  const path = urlSearchParams.toString();
  return (path) ? path : null;
};
