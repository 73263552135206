import React from 'react';
import ConfirmRequestToBook from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/ConfirmRequestToBook';
import ConfirmInstantBook from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/ConfirmInstantBook';
import {
  RESERVATION_TYPE_INSTANT_BOOKING,
  RESERVATION_TYPE_REQUEST_TO_BOOK
} from '@rentivo/gatsby-core/src/constants/lycanConstants';

const ConfirmComponent = ({reservationType}) => {
  if(reservationType === RESERVATION_TYPE_INSTANT_BOOKING) return <ConfirmInstantBook/>;
  if(reservationType === RESERVATION_TYPE_REQUEST_TO_BOOK) return <ConfirmRequestToBook/>;
  return null;
};

export default ConfirmComponent;

