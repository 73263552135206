import React from 'react';
import CheckoutLayout from '@rentivo/gatsby-theme-barcelona/src/components/layouts/CheckoutLayout';
import CheckoutFlow from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/CheckoutFlow';

const CheckoutWrapper = ({children, menuItems}) => {
  return (
    <CheckoutLayout menuItems={menuItems}>
      {children}
    </CheckoutLayout>
  );
};

const CheckoutComponent = ({menuItems}) => {
  return <CheckoutFlow wrapper={CheckoutWrapper} menuItems={menuItems}/>;
};

export default CheckoutComponent;
