import { defineMessages } from 'react-intl';

export const scope = 'containers.CommerceProvider.Confirm.Checkboxes';

export default defineMessages({
  marketingOptInLabel: {
    id: `${scope}.input.marketingOptIn.label`,
    defaultMessage: 'Would you like to receive discounts & special offers from us?',
  },
  termsLabel: {
    id: `${scope}.input.terms.label`,
    defaultMessage: 'I agree to the <tl>Terms and Conditions</tl>',
  },
  privacyLabel: {
    id: `${scope}.input.privacy.label`,
    defaultMessage: 'I agree to the <pl>Privacy Policy</pl>',
  },
  termsPrivacyLabel: {
    id: `${scope}.input.termsAndPolicy.label`,
    defaultMessage: 'I agree to the <tl>Terms and Conditions</tl> & <pl>Privacy Policy</pl>',
  },
  termsAndPrivacyErrorRequired: {
    id: `${scope}.input.termsAndPolicy.error.required`,
    defaultMessage: 'Please agree to our Privacy Policy & Terms and Conditions',
  },
  termsErrorRequired: {
    id: `${scope}.input.terms.error.required`,
    defaultMessage: 'Please agree to our Terms and Conditions',
  },
  privacyErrorRequired: {
    id: `${scope}.input.privacy.error.required`,
    defaultMessage: 'Please agree to our Privacy Policy',
  }
});
