import { defineMessages } from 'react-intl';

export const scope = 'containers.CommerceProvider.SuccessRequestToBook';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Congratulations, you\'re booking request has been submitted',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'We will get back to you shortly with further details',
  },
  longDescription: {
    id: `${scope}.longDescription`,
    defaultMessage: 'You will receive a request confirmation email with all the details of your booking. We will be in touch shortly to confirm the booking and take payment.'
  }
});
