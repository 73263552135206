import React from 'react';
import { compose } from 'redux';
import StepsHeader from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/StepsHeader/component';
import withSteps from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/withSteps';

const enhance = compose(
  withSteps
);

StepsHeader.defaultProps = {
  className: ''
};

export default enhance(StepsHeader);
