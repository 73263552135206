import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import RouteLink from '@rentivo/gatsby-core/src/components/navigation/RouteLink/component';
import { selectRoutePathsConfig, } from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { routePathKeys } from '@rentivo/gatsby-core/src/components/navigation/RouteLink/constants';

const mapStateToProps = createSelector(
  selectRoutePathsConfig,
  (routePaths) => ({
    routePaths
  })
);

RouteLink.defaultProps = {
  route: routePathKeys.ROUTE_BASE_SEARCH
};

const enhance = compose(
  connect(mapStateToProps),
);

export default enhance(RouteLink);
