import React from 'react';
import CartBreakdown from '@rentivo/gatsby-core/src/components/checkout/CartBreakdown/component';
import { compose } from 'redux';
import { withProps } from 'recompose';
import dayjs from 'dayjs';
import { DB_DATE_FORMAT } from '@rentivo/gatsby-core/src/constants/dates';

CartBreakdown.defaultProps = {
  showTitle: true,
  success: false
};

const enhance = compose(
  withProps(({startDate, endDate}) => {
    const parsedStartDate = dayjs(startDate, DB_DATE_FORMAT);
    const parsedEndDate = dayjs(endDate, DB_DATE_FORMAT);
    return {
      nights: parsedEndDate.diff(parsedStartDate, 'day'),
    }
  })
);

export default enhance(CartBreakdown);
