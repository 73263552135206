import React from 'react';
import { Tooltip, Box } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/PaymentCards/messages';
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons/faCcMastercard';
import { faCcAmex } from '@fortawesome/free-brands-svg-icons/faCcAmex';
import { faCcVisa } from '@fortawesome/free-brands-svg-icons/faCcVisa';
import { faCcJcb } from '@fortawesome/free-brands-svg-icons/faCcJcb';
import { faCcDinersClub } from '@fortawesome/free-brands-svg-icons/faCcDinersClub';
import { faCcStripe } from '@fortawesome/free-brands-svg-icons/faCcStripe';
import { faCcPaypal } from '@fortawesome/free-brands-svg-icons/faCcPaypal';
import { faCcAmazonPay } from '@fortawesome/free-brands-svg-icons/faCcAmazonPay';
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons/faCreditCard';
import AwesomeIcon from '@rentivo/gatsby-core/src/components/ui/AwesomeIcon';
import TouchTooltip from '@rentivo/gatsby-core/src/components/ui/TouchTooltip';

const cardMapping = {
  'mastercard': faCcMastercard,
  'master': faCcMastercard,
  'amex': faCcAmex,
  'visa': faCcVisa,
  'jcb': faCcJcb,
  'diners_club': faCcDinersClub,
  'stripe': faCcStripe,
  'paypal': faCcPaypal,
  'amazon': faCcAmazonPay
};

const PaymentCardComponent = ({card, tooltip}) => {
  const { formatMessage } = useIntl();
  if(!card) return null;

  const Icon = <AwesomeIcon icon={(cardMapping[card]) ? cardMapping[card] : faCreditCard} />;
  if(tooltip) {
    const cardName = card.replace('_', ' ').replace(/^\w/, c => c.toUpperCase());

    return (<TouchTooltip hasArrow aria-label="Info tooltip" label={formatMessage(messages.acceptCardTooltip, {card: cardName})}><Box as="span" display="inline-block">{Icon}</Box></TouchTooltip>);
  } else {
    return Icon;
  }
};

export default PaymentCardComponent;
