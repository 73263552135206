import React from 'react';
import PropTypes from 'prop-types';
import PaymentCard from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/PaymentCards/components/PaymentCard/component';

PaymentCard.propTypes = {
  card: PropTypes.string
};

PaymentCard.defaultProps = {
  card: ''
};

export default PaymentCard;
