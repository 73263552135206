import React from 'react';
import ConfirmRequestToBook from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/ConfirmRequestToBook/component';
import { compose } from 'redux';
import withSteps from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/withSteps';
import withCustomer from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/withCustomer';
import withCart from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/withCart';

const enhance = compose(
  withSteps,
  withCustomer,
  withCart()
);

export default enhance(ConfirmRequestToBook);
