import React from 'react';
import {
  StyledDatesReviewDay,
  StyledDatesReviewDayBlock,
  StyledDatesReviewDayLabel,
  StyledDatesReviewDays,
  StyledDatesReview, StyledDatesReviewDayBlockItem, StyledDatesReviewDayBlockItemLabel, StyledTime
} from '@rentivo/gatsby-core/src/components/checkout/DatesReview/styles';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/checkout/DatesReview/messages';
import { Heading } from '@chakra-ui/react';

const DatesReviewComponent = ({
                                className,
                                startDateMonth,
                                startDateDay,
                                startDateDayNumber,
                                endDateMonth,
                                endDateDay,
                                endDateDayNumber,
                                nights,
                                location,
                                showTitle,
                                checkInStartTime,
                                checkInEndTime,
                                checkOutTime
}) => {
  return (
    <StyledDatesReview>
      {showTitle && <Heading as="h3" mb={3}><FormattedMessage {...messages.title} values={{nightsCount: nights, location}}/></Heading>}
      <StyledDatesReviewDays className={className}>
        <StyledDatesReviewDay>
          <StyledDatesReviewDayBlock className="day-block">
            <StyledDatesReviewDayBlockItem>
              <StyledDatesReviewDayBlockItemLabel>{startDateMonth}</StyledDatesReviewDayBlockItemLabel>
              <StyledDatesReviewDayBlockItemLabel>{startDateDayNumber}</StyledDatesReviewDayBlockItemLabel>
            </StyledDatesReviewDayBlockItem>
          </StyledDatesReviewDayBlock>

          <StyledDatesReviewDayLabel>
            <FormattedMessage {...messages.checkinDayLabel} values={{day: startDateDay}}/>
            {checkInStartTime && (
              <StyledTime>
                <FormattedMessage {...messages.checkinStartTimeLabel} values={{time: checkInStartTime}}/>&nbsp;
                {checkInEndTime && <FormattedMessage {...messages.checkinEndTimeLabel} values={{time: checkInEndTime}}/>}
              </StyledTime>
            )}
          </StyledDatesReviewDayLabel>
        </StyledDatesReviewDay>

        <StyledDatesReviewDay>
          <StyledDatesReviewDayBlock className="day-block">
            <StyledDatesReviewDayBlockItem>
              <StyledDatesReviewDayBlockItemLabel>{endDateMonth}</StyledDatesReviewDayBlockItemLabel>
              <StyledDatesReviewDayBlockItemLabel>{endDateDayNumber}</StyledDatesReviewDayBlockItemLabel>
            </StyledDatesReviewDayBlockItem>
          </StyledDatesReviewDayBlock>

          <StyledDatesReviewDayLabel>
            <FormattedMessage {...messages.checkoutDayLabel} values={{day: endDateDay}}/>
            {checkOutTime && (
              <StyledTime>
                <FormattedMessage {...messages.checkoutTimeLabel} values={{time: checkOutTime}}/>
              </StyledTime>
            )}
          </StyledDatesReviewDayLabel>
        </StyledDatesReviewDay>
      </StyledDatesReviewDays>
    </StyledDatesReview>
  )
};

export default DatesReviewComponent;
