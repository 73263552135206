import { defineMessages } from 'react-intl';

export const scope = 'containers.CommerceProvider.InvalidCart';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Your shopping cart is no longer valid',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Please go back to the search page and start again.',
  },
  longDescription: {
    id: `${scope}.longDescription`,
    defaultMessage: 'Looks like your cart is no longer valid. This is sometimes due to your property no longer being available. We recommend you go back to the search page and start again.',
  }
});
