import { defineMessages } from 'react-intl';

export const scope = 'containers.CommerceProvider.Review';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Review your stay',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Please confirm your details',
  },
  stepName: {
    id: `${scope}.step.name`,
    defaultMessage: 'Review your stay',
  },
  stepDescription: {
    id: `${scope}.step.description`,
    defaultMessage: 'Confirm your details',
  },
});
