import React from 'react';
import { Stack, Checkbox, Link } from '@chakra-ui/react';
import FormItem from '@rentivo/gatsby-core/src/components/forms/FormItem';
import { useIntl, FormattedMessage } from 'react-intl';
import messages
  from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/Checkboxes/messages';

const CheckboxesComponent = ({checkboxes: { showMarketingOptInConfig, termsAndConditions, privacyPolicy }}) => {
  if(!privacyPolicy && !termsAndConditions && !showMarketingOptInConfig) return null;

  const tl = (...children) => <Link href={termsAndConditions} target="_blank">{children}</Link>;
  const pl = (...children) => <Link href={privacyPolicy} target="_blank">{children}</Link>;

  return (
    <Stack spacing={2} className="booking-checks">
      {(privacyPolicy || termsAndConditions) && (

        <FormItem
          name="termsAndConditions"
          isCheckbox
          rules={{
            required: true
          }}
          render={props => (
            <Checkbox
              colorScheme="primary"
              {...props}
            >
              {(privacyPolicy && termsAndConditions) ?
                <FormattedMessage{...messages.termsPrivacyLabel} values={{tl, pl}}/> :
                (termsAndConditions) ?
                  <FormattedMessage{...messages.termsLabel} values={{tl}} /> :
                  <FormattedMessage{...messages.privacyLabel} values={{pl}} />}
            </Checkbox>
          )}
        />

      )}
      {showMarketingOptInConfig && (
        <FormItem
          isCheckbox
          name="marketingOptIn"
          render={props => (
            <Checkbox
              colorScheme="primary"
              {...props}
            >
              <FormattedMessage {...messages.marketingOptInLabel}/>
            </Checkbox>
          )}
        />
      )}
    </Stack>
  )
};

export default CheckboxesComponent;
