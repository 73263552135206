import DatesReview from '@rentivo/gatsby-core/src/components/checkout/DatesReview/component';
import { compose } from 'redux';
import { mapProps } from 'recompose';
import moment from 'moment';
import { DB_DATE_FORMAT } from '@rentivo/gatsby-core/src/constants/dates';

DatesReview.defaultProps = {
  location: null,
  startDate: null,
  endDate: null,
  showTitle: true
};

const enhance = compose(
  mapProps(({startDate, endDate, location, showTitle, checkInStartTime, checkInEndTime, checkOutTime}) => {
    const parsedStartDate = moment(startDate, DB_DATE_FORMAT);
    const parsedEndDate = moment(endDate, DB_DATE_FORMAT);
    return {
      startDateMonth: parsedStartDate.format('MMM'),
      startDateDayNumber: parsedStartDate.format('D'),
      startDateDay: parsedStartDate.format('dddd'),
      endDateMonth: parsedEndDate.format('MMM'),
      endDateDayNumber: parsedEndDate.format('D'),
      endDateDay: parsedEndDate.format('dddd'),
      nights: parsedEndDate.diff(parsedStartDate, 'day'),
      location,
      showTitle,
      checkInStartTime,
      checkInEndTime,
      checkOutTime
    }
  })
);

export default enhance(DatesReview);
