import React from 'react';
import Review from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Review/component';
import { compose } from 'redux';
import withSteps from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/withSteps';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Review/messages';
import { CHECKOUT_STEP_REVIEW_PATH } from '@rentivo/gatsby-core/src/containers/CommerceProvider/constants';

const enhance = compose(
  withSteps
);

Review.defaultProps = {
  path: CHECKOUT_STEP_REVIEW_PATH,
  name: <FormattedMessage {...messages.stepName}/>,
  description: <FormattedMessage {...messages.stepDescription}/>
};

export default enhance(Review);
