import { Heading, chakra } from '@chakra-ui/react';

export const StyledPaymentForm = chakra('div', {
  baseStyle: {
    'iframe': {
      maxH: 10
    }
  }
});

/*
export const StyledPaymentForm = styled.div`
  iframe {
    max-height: calc(${props => props.theme['input-height-base']} + ${props => props.theme['padding-xs']});
  }
`;
*/

export const StyledPaymentFormGroup = chakra('div', {
  baseStyle: {
    mb: 6,
    '&:last-child': {
      mb: 0
    }
  }
});

/*
export const StyledPaymentFormGroup = styled.div`
  margin-bottom: ${props => props.theme['padding-lg']};
  &:last-child {
    margin-bottom: 0;
  }
`;
*/

export const StyledPaymentFormAddressText = chakra('div', {
  baseStyle: {

  }
});


export const StyledPaymentFormSpreedlyErrors = chakra('div', {
  baseStyle: {
    my: 2,
    mx: 0,
    '> div': {
      mb: 2,
      '&:last-child': {
        mb: 0
      }
    }
  }
});

export const StyledPaymentFormBillingTitle = chakra(Heading, {
  baseStyle: {
    alignItems: 'center',
    mb: 3,
    justifyContent: 'space-between',
    display: { base: 'block', md: 'flex' },
    'span': {
      display: 'block'
    },
    button: {
      float: { base: 'none' },
      my: { base: 2 }
    }
  }
});

/*
export const StyledPaymentFormBillingTitle = styled(Heading)`
  align-items: center;
  margin-bottom: ${props => props.theme['padding-sm']};
  display: flex;
  justify-content: space-between;
  @media(max-width: ${props => props.theme['screen-md']}) {
     display: block;
  }
  span {
    display: block;
  }
  button {
    @media(max-width: ${props => props.theme['screen-md']}) {
      float:none;
      margin: ${props => props.theme['padding-xs']} 0;
    }
  }
`;
*/
