import { defineMessages } from 'react-intl';

export const scope = 'containers.CommerceProvider.CartBreakdown';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Review your order details',
  },
  tableItemLabel: {
    id: `${scope}.tableItem.label`,
    defaultMessage: 'Item',
  },
  tableQuantityLabel: {
    id: `${scope}.tableQuantity.label`,
    defaultMessage: 'Quantity',
  },
  tableTotalPriceLabel: {
    id: `${scope}.tableTotalPrice.label`,
    defaultMessage: 'Total price',
  },
  tableDueTodayLabel: {
    id: `${scope}.tableDueToday.label`,
    defaultMessage: 'Due today',
  },
  tablePaidTodayLabel: {
    id: `${scope}.tablePaidToday.label`,
    defaultMessage: 'Paid today',
  },
  tableFootTotalLabel: {
    id: `${scope}.tableFootTotal.label`,
    defaultMessage: 'Total',
  },
  tablePropertyQuantityLabel: {
    id: `${scope}.tablePropertyQuantity.label`,
    defaultMessage: '{nightsCount} nights',
  },
  tableProductQuantityLabel: {
    id: `${scope}.tableProductQuantity.label`,
    defaultMessage: 'x {quantityCount}',
  },
  guestsText: {
    id: `${scope}.guests.text`,
    defaultMessage: '{guestsCount, plural, =0 {0 guests} one {1 guest} other {{guestsCount} guests}}{petsCount, plural, =0 {} one {, 1 pet} other {, {petsCount} pets}}'
  },
  datesText: {
    id: `${scope}.dates.text`,
    defaultMessage: '{startDate} — {endDate}'
  }
});
