import { countries } from 'countries-list';

export const getCountries = (important = [], include = [], exclude = []) => {

  // Step 1, convert obj to array
  const countriesArray = [];
  for (let key in countries) {
    let country = countries[key];
    country.code = key;

    if(include.length === 0) {
      countriesArray.push(country);
    } else if(include.length > 0 && include.includes(key)) {
      countriesArray.push(country);
    }
  }

  const excludedCountries = countriesArray.filter(c => !exclude.includes(c.code));

  return excludedCountries.sort((a, b) => {
    if(important.includes(a.code) && !important.includes(b.code)) {
      return -1;
    }

    if(important.includes(b.code) && !important.includes(a.code)) {
      return 1;
    }

    return 0;
  });
};
