import React, { useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from '@reach/router';
import { CommerceProvider } from '@rentivo/gatsby-core/src/context/CommerceContext';
import { PropertyProvider } from '@rentivo/gatsby-core/src/context/PropertyContext';
import Loader from '@rentivo/gatsby-core/src/components/generic/Loader';
import history from '@rentivo/gatsby-core/src/utils/history';

const Commerce = ({sanitizedChildRoutes, invalidComponent, commerce, locale, setDefaultValues, handleRouteChange, isValidCart, property}) => {
  useEffect(() => history.listen(({action, location}) => handleRouteChange(location, action)),[]); // Handle route change
  useEffect(() => {
    setDefaultValues({}); // Only fires when Persist has loaded (using persist gate below)
  }, [locale]);

  return (
    <CommerceProvider commerce={{...commerce, inCheckout: true}}>
      {isValidCart ? (
        <PropertyProvider property={property}>
          <Router>
            {sanitizedChildRoutes}
          </Router>
        </PropertyProvider>
      ) : (
        <>{invalidComponent}</>
      )}
    </CommerceProvider>
  );
};

const CommerceProviderComponent = ({store, persistor, ...rest}) => {
  return (
    <PersistGate loading={<Loader/>} persistor={persistor}>
      <Commerce {...rest} />
    </PersistGate>
  );
};

export default CommerceProviderComponent;
