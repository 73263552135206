import { defineMessages } from 'react-intl';

export const scope = 'containers.CommerceProvider.Confirm.ConfirmInstantBook';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Confirm & pay',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Enter your billing details below and confirm your booking today',
  },
  confirmButtonLabel: {
    id: `${scope}.confirmButton.label`,
    defaultMessage: 'Confirm & pay — {totalDue}',
  }
});
