import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/forms/CustomerInfoForm/messages';
import { useLycanFormattedMessage } from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage';
import { Box, Input, Select, Heading } from '@chakra-ui/react';
import FormItem from '@rentivo/gatsby-core/src/components/forms/FormItem';
import { Row, Col } from '@rentivo/gatsby-core/src/components/ui/Row';

const CustomerInfoFormComponent = ({form, phoneRequired, loading, countries = [], clearForm, defaultValues, styleProps = {}}) => {
  const { formatMessage } = useIntl();
  const { reset } = form;

  const formatLycanMessage = useLycanFormattedMessage();
  const countryOptions = countries.map(c => <option key={c.code} value={c.code}>{formatLycanMessage(c.name, c.name)}</option>);

  const formRows = {
    spacing: 4,
    mb: 4
  };

  const doubleFormCols = {
    colSpan: {
      base: 24,
      lg: 12
    },
    mb: {
      base: 3,
      lg: 0
    }
  };

  const quadFormCols = {
    colSpan: {
      base: 12,
      lg: 6
    }
  };

  return (
    <Box className="enquiry-form" {...styleProps}>
        <Heading as="h3" mb={4}><FormattedMessage {...messages.contactInfoTitle}/></Heading>

        <Row {...formRows}>
          <Col {...doubleFormCols}>
            <FormItem
              name="firstName"
              label={formatMessage(messages.inputFirstNameLabel)}
              rules={{
                required: true
              }}
              render={props => (
                <Input
                  type="text"
                  placeholder={formatMessage(messages.inputFirstNameLabel)}
                  {...props}
                />
              )}
            />
          </Col>
          <Col {...doubleFormCols}>

            <FormItem
              name="lastName"
              label={formatMessage(messages.inputLastNameLabel)}
              rules={{
                required: true
              }}
              render={props => (
                <Input
                  type="text"
                  placeholder={formatMessage(messages.inputLastNameLabel)}
                  {...props}
                />
              )}
            />

          </Col>
        </Row>

        <Row {...formRows} mb={6}>
          <Col {...doubleFormCols}>

            <FormItem
              name="email"
              label={formatMessage(messages.inputEmailLabel)}
              rules={{
                required: true,
                email: true
              }}
              render={props => (
                <Input
                  type="email"
                  placeholder={formatMessage(messages.inputEmailLabel)}
                  {...props}
                />
              )}
            />

          </Col>
          <Col {...doubleFormCols}>

            <FormItem
              name="phone"
              label={formatMessage(messages.inputPhoneLabel)}
              rules={{
                required: phoneRequired
              }}
              render={props => (
                <Input
                  type="text"
                  placeholder={formatMessage(messages.inputPhoneLabel)}
                  {...props}
                />
              )}
            />

          </Col>
        </Row>

        <Heading as="h3" mb={4}><FormattedMessage {...messages.contactInfoTitle}/></Heading>

        <Row {...formRows}>
          <Col {...doubleFormCols}>

            <FormItem
              name="addressLine1"
              label={formatMessage(messages.inputAddressLineOneLabel)}
              rules={{
                required: true
              }}
              render={props => (
                <Input
                  type="text"
                  placeholder={formatMessage(messages.inputAddressLineOneLabel)}
                  {...props}
                />
              )}
            />

          </Col>
          <Col {...doubleFormCols}>

            <FormItem
              name="addressLine2"
              label={formatMessage(messages.inputAddressLineTwoLabel)}
              render={props => (
                <Input
                  type="text"
                  placeholder={formatMessage(messages.inputAddressLineTwoLabel)}
                  {...props}
                />
              )}
            />

          </Col>
        </Row>

        <Row {...formRows}>
          <Col {...doubleFormCols}>

            <FormItem
              name="city"
              label={formatMessage(messages.inputCityLabel)}
              rules={{
                required: true
              }}
              render={props => (
                <Input
                  type="text"
                  placeholder={formatMessage(messages.inputCityLabel)}
                  {...props}
                />
              )}
            />

          </Col>
          <Col {...doubleFormCols}>

            <FormItem
              name="state"
              label={formatMessage(messages.inputStateLabel)}
              render={props => (
                <Input
                  type="text"
                  placeholder={formatMessage(messages.inputStateLabel)}
                  {...props}
                />
              )}
            />

          </Col>
        </Row>

        <Row {...formRows}>
          <Col {...doubleFormCols}>

            <FormItem
              name="zip"
              label={formatMessage(messages.inputZipLabel)}
              rules={{
                required: true
              }}
              render={props => (
                <Input
                  type="text"
                  placeholder={formatMessage(messages.inputZipLabel)}
                  {...props}
                />
              )}
            />

          </Col>
          <Col {...doubleFormCols}>

            <FormItem
              name="country"
              label={formatMessage(messages.inputCountryLabel)}
              rules={{
                required: true
              }}
              render={props => (
                <Select
                  {...props}
                  placeholder={formatMessage(messages.inputCountryLabel)}
                >
                  {countryOptions}
                </Select>
              )}
            />

          </Col>
        </Row>
    </Box>
  );

};

export default CustomerInfoFormComponent;
