import React from 'react';
import { createSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  commerceDataSelectorEmulator,
  commercePricingSelectorEmulator, commerceReservationDataPricingSelectorEmulator, makeSelectCheckboxes,
  selectCommerce,
  selectCommerceCart,
  selectCommerceCartTotal,
  selectCommerceCartTotalChargeDueToday,
  selectCommerceCartTotalDueToday,
  selectCommerceChargeCurrency,
  selectCommerceChargeExchangeRate,
  selectCommerceData,
  selectCommerceDidInvalidate,
  selectCommerceIsThreeDSChallenge,
  selectCommerceTransactionToken,
  selectCommercePotentialData,
  selectCommerceError,
  selectCommerceIsFetching
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/selectors';
import {
  startChangeStep,
  startCreateInstantBookReservation, startCreateRequestToBookReservation
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/actions';
import get from 'lodash/get';
import {
  makeSelectGetExchangedCurrency,
  selectCurrentCurrency
} from '@rentivo/gatsby-core/src/containers/CurrencyProvider/selectors';
import {
  makeSelectPricingDataPairingDetails,
  makeSelectPricingDataSpreedlyEnvironment,
  selectPricingDataStayBreakdownArrivalDate,
  selectPricingDataStayBreakdownDepartureDate,
  selectPricingDataStayBreakdownGuests,
  selectPricingDataStayBreakdownNoNights,
  selectPricingDataStayBreakdownPets
} from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors';
import { createReservation } from '@rentivo/gatsby-core/src/containers/CommerceProvider/actions';
import { selectCheckoutPaymentDefaultAcceptedPaymentBrandsConfig, selectCheckoutPaymentShowGatewaysConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';

const withCart = (success = false) => WrappedComponent => {
  const withCartComponent = (props) => {
    return <WrappedComponent {...props} />;
  };

  const commercePricingSelector = createSelector(
    selectPricingDataStayBreakdownNoNights,
    selectPricingDataStayBreakdownGuests,
    selectPricingDataStayBreakdownPets,
    selectPricingDataStayBreakdownArrivalDate,
    selectPricingDataStayBreakdownDepartureDate,
    makeSelectPricingDataSpreedlyEnvironment,
    makeSelectPricingDataPairingDetails,
    (noNights, guests, pets, startDate, endDate, spreedlyEnvironment, { merchantName, supportedCards, pairings }) => {

      const reducedPaymentGateways = [];
      for(const pairing of pairings) {
        let madeADefault = false;
        for(const currency of pairing.supportedCurrencies) {
          console.log('debug', currency, pairing);
          if(pairing && pairing.gateway) {
            reducedPaymentGateways.push({
              id: `${pairing.gateway.id}_${currency}`,
              gatewayId: pairing.gateway.id,
              name: pairing.gateway.descriptiveName,
              currency: currency,
              isDefault: (currency === pairing.preferredCurrency && madeADefault === false)
            });
          }

          if(currency === pairing.preferredCurrency) {
            madeADefault = true;
          }
        }
      }

      

      return {
        noNights,
        guests,
        pets,
        startDate,
        endDate,
        spreedlyEnvironment,
        merchantName,
        supportedCards,
        pairings,
        reducedPaymentGateways: reducedPaymentGateways
      }
    }
  );

  const commerceSelector = createSelector(
    selectCommerceCart,
    selectCommerceChargeCurrency,
    selectCommerceChargeExchangeRate,
    selectCommerceCartTotalDueToday,
    selectCommerceCartTotalChargeDueToday,
    selectCommerceCartTotal,
    (cart, chargeCurrency, chargeExchangeRate, cartTotalDueToday, cartTotalChargeDueToday, cartTotal) => ({
      cart,
      chargeCurrency,
      chargeExchangeRate,
      cartTotalDueToday,
      cartTotalChargeDueToday,
      cartTotal
    })
  );

  const mapStateToProps = (state, { supportedCards }) => createSelector(
    selectCurrentCurrency,
    selectCommerceData,
    selectCommerceIsFetching,
    selectCommerceDidInvalidate,
    selectCommerceError,
    selectCommerceIsThreeDSChallenge,
    selectCommerceTransactionToken,
    selectCommercePotentialData,
    selectCheckoutPaymentDefaultAcceptedPaymentBrandsConfig,
    selectCheckoutPaymentShowGatewaysConfig,
    makeSelectCheckboxes,
    makeSelectGetExchangedCurrency,
    (currentCurrency, reservationData, reservationLoading, reservationDidInvalidate, reservationError, reservationIsThreeDSChallenge, transactionToken, potentialData, defaultAcceptedPaymentBrands, showGateways, checkboxes, getExchangedCurrency) => ({
      currentCurrency,
      reservationData,
      reservationLoading,
      reservationDidInvalidate,
      reservationError,
      reservationIsThreeDSChallenge,
      transactionToken,
      potentialData,
      showGateways,
      checkboxes,
      cards: (supportedCards) ? supportedCards : defaultAcceptedPaymentBrands,
      getExchangedCurrency
    })
  );

  const mapDispatchToProps = dispatch => ({
    createInstantBookReservation: (token, browserInfo, pg) => dispatch(startCreateInstantBookReservation(token, browserInfo, pg)),
    createRequestToBookReservation: () => dispatch(startCreateRequestToBookReservation())
  });

  const pricingMapStateToProps = (success) ? commerceReservationDataPricingSelectorEmulator(commercePricingSelector) : commercePricingSelectorEmulator(commercePricingSelector);
  const commerceMapStateToProps = (success) ? commerceDataSelectorEmulator(commerceSelector) : commerceSelector;

  const enhance = compose(
    connect(pricingMapStateToProps),
    connect(commerceMapStateToProps),
    connect(mapStateToProps, mapDispatchToProps)
  );

  return enhance(withCartComponent);

};

export default withCart;
