import React from 'react';
import { sanitizeSteps } from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/StepsHeader/utils';
import Steps from '@rentivo/gatsby-core/src/components/ui/Steps';

const StepsHeaderComponent = ({steps, className, currentStep}) => {
  return (
    <Steps className={className} current={currentStep} steps={sanitizeSteps(steps)}/>
  )
};

export default StepsHeaderComponent;
