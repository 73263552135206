import React from 'react';
import { useIntl } from 'react-intl';
import {
  CHECKOUT_STEP_CONFIRM_PATH,
  CHECKOUT_STEP_CUSTOMER_INFO_PATH,
  CHECKOUT_STEP_REVIEW_PATH, CHECKOUT_STEP_SUCCESS_PATH
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/constants';
import reviewMessages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Review/messages';
import customerInfoMessages
  from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/CustomerInfo/messages';
import confirmMessages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/messages';
import Review from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Review';
import CustomerInfo from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/CustomerInfo';
import Confirm from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm';
import Success from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Success';
import CommerceProvider from '@rentivo/gatsby-core/src/containers/CommerceProvider';
import InvalidCart from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/InvalidCart';
import NotFound from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/NotFound';

const CheckoutFlowComponent = ({wrapper: Wrapper, ...rest}) => {
  const { formatMessage } = useIntl();
  const ReviewPage = (props) => <Wrapper {...props} {...rest}><Review/></Wrapper>;
  const CustomerInfoPage = (props) => <Wrapper {...props} {...rest}><CustomerInfo/></Wrapper>;
  const ConfirmPage = (props) => <Wrapper {...props} {...rest}><Confirm/></Wrapper>;
  const SuccessPage = (props) => <Wrapper {...props} {...rest}><Success/></Wrapper>;
  const InvalidCartPage = (props) => <Wrapper {...props} {...rest}><InvalidCart/></Wrapper>;
  const NotFoundPage = (props) => <Wrapper {...props} {...rest}><NotFound/></Wrapper>;

  return (
    <CommerceProvider>
      <ReviewPage
        path={CHECKOUT_STEP_REVIEW_PATH}
        name={formatMessage(reviewMessages.stepName)}
        description={formatMessage(reviewMessages.stepDescription)}
        review
      />
      <CustomerInfoPage
        path={CHECKOUT_STEP_CUSTOMER_INFO_PATH}
        name={formatMessage(customerInfoMessages.stepName)}
        description={formatMessage(customerInfoMessages.stepDescription)}
        info
      />
      <ConfirmPage
        path={CHECKOUT_STEP_CONFIRM_PATH}
        name={formatMessage(confirmMessages.stepName)}
        description={formatMessage(confirmMessages.stepDescription)}
        review
      />
      <SuccessPage
        path={CHECKOUT_STEP_SUCCESS_PATH}
        success
      />
      <InvalidCartPage invalid />
      <NotFoundPage default />
    </CommerceProvider>
  );
};

export default CheckoutFlowComponent;
