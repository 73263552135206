import React from 'react';
import PaymentCards from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/PaymentCards/component';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import { commercePricingSelectorEmulator } from '@rentivo/gatsby-core/src/containers/CommerceProvider/selectors';
import { connect } from 'react-redux';
import { makeSelectPricingDataPairingDetails, } from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors';
import { selectCheckoutPaymentDefaultAcceptedPaymentBrandsConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';
import PropTypes from 'prop-types';

const commercePricingSelector = createSelector(
  makeSelectPricingDataPairingDetails,
  ({supportedCards}) => {
    return {
      supportedCards
    }
  }
);

const mapStateToProps = (state, { supportedCards }) => createSelector(
  selectCheckoutPaymentDefaultAcceptedPaymentBrandsConfig,
  (defaultAcceptedPaymentBrands) => {
    return {
      cards: (supportedCards) ? supportedCards : defaultAcceptedPaymentBrands
    }
  }
);

const enhance = compose(
  connect(commercePricingSelectorEmulator(commercePricingSelector)),
  connect(mapStateToProps)
);

PaymentCards.propTypes = {
  secure:            PropTypes.bool,
  tooltip:           PropTypes.bool,
  cards:             PropTypes.array,
  className:         PropTypes.string,
  style:             PropTypes.object
};

PaymentCards.defaultProps = {
  tooltip:           true,
  secure:            true,
  cards:             ['visa', 'mastercard']
};

export default enhance(PaymentCards);
