import { chakra } from '@chakra-ui/react';
import DatesReview from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Review/components/DatesReview';
import PropertyCancellation from '@rentivo/gatsby-core/src/components/property/PropertyCancellation';
import ChangeGuests from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Review/components/ChangeGuests';

const reviewStyleProps = {
  baseStyle: {
    mb: 10
  }
};

export const StyledDatesReview = chakra(DatesReview, reviewStyleProps);
export const StyledChangeGuests = chakra(ChangeGuests, reviewStyleProps);
export const StyledCancellationPolicy = chakra(PropertyCancellation, {
  baseStyle: {
    pt: { base: 0, md: 6 },
    pb: { base: 0, md: 2 },
    pl: { base: 0, md: 8 },
    pr: { base: 0, md: 8 },
    ml: { base: 0, md: -8 },
    mr: { base: 0, md: -8 },
    mb: { base: 0, md: -10 },
    bg: { base: 'transparent', md: 'gray.100' }
  }
});
