import React from 'react';
import StepLayout from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/StepLayout/component';
import {
  Alert,
  AlertIcon,
  Heading,
  Text,
  Box
} from '@chakra-ui/react';
import { Button } from '@rentivo/gatsby-core/index';
import Form from '@rentivo/gatsby-core/src/components/forms/Form';
import {
  StyledStepPageFooter,
  StyledStepPageHeader,
  StyledStepPageBody
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/styles';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/ConfirmRequestToBook/messages';
import commerceMessages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/messages';
import CartBreakdown from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/CartBreakdown';
import Loader from '@rentivo/gatsby-core/src/components/generic/Loader';
import { setCustomerBilling } from '@rentivo/gatsby-core/src/containers/CommerceProvider/actions';
import Checkboxes from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/Checkboxes';
import { useForm } from 'react-hook-form';
import ReservationErrors
  from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/ReservationErrors';
import useDebounce from '@rentivo/gatsby-core/src/hooks/useDebounce';

const ConfirmRequestToBookComponent = ({
   hasNextStep,
   hasPrevStep,
   nextStep,
   prevStep,
   isPricingLoading,
   createRequestToBookReservation,
   reservationData,
   reservationLoading,
   reservationDidInvalidate,
   reservationError,
   dispatch
}) => {

  const defaultValues = {
    termsAndConditions: false,
    marketingOptIn: false
  };

  const form = useForm({defaultValues});

  const handleRequestToBook = useDebounce(async (values) => {
    await dispatch(setCustomerBilling(values, true));
    createRequestToBookReservation();
  }, 500);

  return (
    <Form form={form} onSubmit={handleRequestToBook}>
      <StepLayout>
        <StyledStepPageHeader>
          <Heading as="h1"><FormattedMessage {...messages.title}/></Heading>
          <Text variant="light"><FormattedMessage {...messages.description}/></Text>
        </StyledStepPageHeader>

        <StyledStepPageBody>
          <Box>
            {(reservationLoading) && <Loader/>}
            <ReservationErrors/>
          </Box>
          <CartBreakdown/>
          <Checkboxes/>
        </StyledStepPageBody>

        <StyledStepPageFooter>
          {hasPrevStep && <Button useDebounce disabled={isPricingLoading || reservationLoading} onClick={() => prevStep()} className="prev"><FormattedMessage {...commerceMessages.prevButtonLabel}/></Button>}
          {hasNextStep && <Button disabled={isPricingLoading || reservationLoading} htmlType="submit" className="next" type="primary"><FormattedMessage {...messages.confirmButtonLabel}/></Button>}
        </StyledStepPageFooter>
      </StepLayout>
    </Form>
  )
};

export default ConfirmRequestToBookComponent;

