import PaymentForm from '@rentivo/gatsby-core/src/components/forms/PaymentForm/component';
import { withTheme } from '@emotion/react';
import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { makeSelectCountries } from '@rentivo/gatsby-core/src/selectors/countries';

const mapStateToProps = createSelector(
  makeSelectCountries,
  (countries) => ({
    countries
  })
);

const enhance = compose(
  connect(mapStateToProps),
  withTheme
);

PaymentForm.defaultProps = {
  loading: false,
  defaultValues: {},
  spreedlyEnvironment: null,
  cardsComponent: () => null,
  checkboxesComponent: () => null
};

export default enhance(PaymentForm);
