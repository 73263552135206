import React from 'react';
import CustomerInfo from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/CustomerInfo/component';
import { compose } from 'redux';
import withSteps from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/withSteps';
import messages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/CustomerInfo/messages';
import { FormattedMessage } from 'react-intl';
import { CHECKOUT_STEP_CUSTOMER_INFO_PATH } from '@rentivo/gatsby-core/src/containers/CommerceProvider/constants';
import withCustomer from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/withCustomer';

const enhance = compose(
  withSteps,
  withCustomer
);

CustomerInfo.defaultProps = {
  path: CHECKOUT_STEP_CUSTOMER_INFO_PATH,
  name: <FormattedMessage {...messages.stepName}/>,
  description: <FormattedMessage {...messages.stepDescription}/>
};

export default enhance(CustomerInfo);
