import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PaymentCard from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/PaymentCards/components/PaymentCard';
import { faLockAlt } from '@fortawesome/pro-regular-svg-icons/faLockAlt';
import { StyledPaymentCards } from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/PaymentCards/styles';

const PaymentCardsComponent = ({cards, secure, className, style, tooltip }) => {
  return (
    <StyledPaymentCards className={classNames('payment-cards', className)} style={style}>
      {secure && (<FontAwesomeIcon icon={faLockAlt} />)}
      {cards.map((card) => <PaymentCard key={card} tooltip={tooltip} card={card}/>)}
    </StyledPaymentCards>
  );
};

export default PaymentCardsComponent;
