import { defineMessages } from 'react-intl';

export const scope = 'containers.CommerceProvider.CustomerInfo';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Your details',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Please enter your contact & billing details',
  },
  stepName: {
    id: `${scope}.step.name`,
    defaultMessage: 'Info',
  },
  stepDescription: {
    id: `${scope}.step.description`,
    defaultMessage: 'Enter your details',
  },
});
