import { createSelector } from 'reselect';
import { getCountries } from '@rentivo/gatsby-core/src/utils/countries';
import {
  selectSiteCountriesExcludeConfig,
  selectSiteCountriesImportantConfig,
  selectSiteCountriesIncludeConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';

export const makeSelectCountries = createSelector(
  selectSiteCountriesImportantConfig,
  selectSiteCountriesIncludeConfig,
  selectSiteCountriesExcludeConfig,
(important, include, exclude) => {
    return getCountries(important, include, exclude);
});
