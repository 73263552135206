import React from 'react';
import get from 'lodash/get';
import ReservationBreakdown from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Success/components/ReservationBreakdown/component';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import {
  selectCommerceData, selectCommerceReservationData,
  selectCommerceReservationDataAdults,
  selectCommerceReservationDataChildren,
  selectCommerceReservationDataId, selectCommerceReservationDataInfants, selectCommerceReservationDataPets,
  selectCommerceReservationType
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/selectors';
import { connect } from 'react-redux';
import { selectCheckoutSuccessReservationIdPathConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';

const mapStateToProps = createSelector(
  selectCheckoutSuccessReservationIdPathConfig,
  selectCommerceReservationType,
  selectCommerceData,
  selectCommerceReservationData,
  selectCommerceReservationDataId,
  selectCommerceReservationDataAdults,
  selectCommerceReservationDataChildren,
  selectCommerceReservationDataInfants,
  selectCommerceReservationDataPets,
  (reservationIdPath, reservationType, data, reservationData, reservationId, adults, children, infants, pets) => {
    return {
      reservationType,
      data,
      reservationId: get(reservationData, reservationIdPath, reservationId),
      adults,
      children,
      infants,
      pets
    }
  }
);

const enhance = compose(
  connect(mapStateToProps)
);

export default enhance(ReservationBreakdown);
