import React from 'react';
import { createSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  selectCommerceCustomer, selectCommerceCustomerBilling, selectCommerceCustomerInfo,
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/selectors';
import {
  setCustomerBilling,
  setCustomerInfo
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/actions';
import { selectCheckoutInfoPaymentRequiredConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';

const withCustomer = WrappedComponent => {

  const withCustomerComponent = (props) => {
    return <WrappedComponent {...props} />;
  };

  const mapStateToProps = createSelector(
    selectCommerceCustomer,
    selectCommerceCustomerInfo,
    selectCommerceCustomerBilling,
    (customer, info, billing) => ({
      customer,
      info,
      billing
    })
  );

  const mapDispatchToProps = dispatch => ({
    setCustomerInfo: (info, merge) => dispatch(setCustomerInfo(info, merge)),
    setCustomerBilling: (billing, merge) => dispatch(setCustomerBilling(billing, merge))
  });

  const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps)
  );

  return enhance(withCustomerComponent);

};

export default withCustomer;
