import { compose } from 'redux';
import { withPageContext } from '@rentivo/gatsby-core/src/context/PageContext';
import PricingProvider from '@rentivo/gatsby-core/src/containers/PricingProvider/component';
import { createSelector } from 'reselect';
import { selectPageContextProperty } from '@rentivo/gatsby-core/src/selectors/pageContext';
import { connect } from 'react-redux';
import { startSetDefaultValues } from '@rentivo/gatsby-core/src/containers/PricingProvider/actions';
import { selectPropertyPricingDataConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { selectPropertyId, selectPropertyName } from '@rentivo/gatsby-core/src/selectors/property';
import { withCommerce } from '@rentivo/gatsby-core/src/context/CommerceContext';
import { makeSelectCommercePropertyAndPricingInCart } from '@rentivo/gatsby-core/src/containers/CommerceProvider/selectors';
import isEmpty from 'lodash/isEmpty';
import { withRouter } from '@rentivo/gatsby-core/src/context/RouterContext';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';

PricingProvider.defaultProps = {
  property: null
};

const mapStateToProps = (state, { commerce }) => createSelector(
  selectPropertyPricingDataConfig,
  makeSelectCommercePropertyAndPricingInCart,
  (dataConfig, { propertyInCart, pricingInCart }) => {
    const inCheckout = !isEmpty(commerce);
    return {
      dataConfig,
      propertyInCart,
      providedPricing: (inCheckout && pricingInCart) ? pricingInCart : null,
      inCheckout
    }
  }
);

// This uses to props > the page context, so you can pass a property in
const pageContextSelector = (state, { property, commerce, propertyInCart, pricingDataInCart, location: { state: routerState } }) => createSelector(
  selectPageContextProperty,
  (contextProperty) => {
    const providedProperty = (!isEmpty(commerce) && propertyInCart) ? propertyInCart : contextProperty; // Use cart property over context property
    property = (routerState && routerState.property) ? routerState.property : property; // Attempt to get property from router state first
    property = (property) ? property : providedProperty; // Use prop property over providedProperty

    return {
      property,
      propertyId: selectPropertyId(property),
      propertyName: selectPropertyName(property)
    };
  }
)(state);

const mapDispatchToProps = (dispatch) => ({
    setDefaultValues: (params) => dispatch(startSetDefaultValues(params)),
});

const enhance = compose(
  withProperty(),
  withRouter,
  withCommerce(),
  connect(mapStateToProps, mapDispatchToProps),
  withPageContext(pageContextSelector)
);

export default enhance(PricingProvider);
