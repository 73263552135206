import { defineMessages } from 'react-intl';

export const scope = 'containers.CommerceProvider.ReservationErrors';

export default defineMessages({
  backToProperty: {
    id: `${scope}.backToProperty`,
    defaultMessage: 'Try different dates',
  },
  backToSearch: {
    id: `${scope}.backToSearch`,
    defaultMessage: 'Back to search',
  }
});
