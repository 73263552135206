import { defineMessages } from 'react-intl';

export const scope = 'components.checkout.DatesReview';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: '{nightsCount} nights in {location}',
  },
  checkinDayLabel: {
    id: `${scope}.checkinDay.label`,
    defaultMessage: '{day} check-in',
  },
  checkoutDayLabel: {
    id: `${scope}.checkoutDay.label`,
    defaultMessage: '{day} check-out',
  },
  changeDatesLink: {
    id: `${scope}.changeDates.link`,
    defaultMessage: 'Go back to change your dates',
  },
  checkinStartTimeLabel: {
    id: `${scope}.checkinStartTime.label`,
    defaultMessage: 'Check-in from {time}',
  },
  checkinCheckoutTimesTitle: {
    id: `${scope}.checkinCheckoutTimes.title`,
    defaultMessage: 'Check-in & Check-out',
  },
  checkinEndTimeLabel: {
    id: `${scope}.checkinEndTime.label`,
    defaultMessage: 'until {time}',
  },
  checkoutTimeLabel: {
    id: `${scope}.checkoutTime.label`,
    defaultMessage: 'Check-out by {time}',
  },
});
