import React from 'react';
import { compose } from 'redux';
import { withHandlers } from 'recompose';
import CommerceProvider from '@rentivo/gatsby-core/src/containers/CommerceProvider/component';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectDefaultCurrencyConfig, selectRoutePathsConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';
import {
  getStepsAndSanitizeChildren
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/utils';
import {
  setStep,
  startSetDefaultValues
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/actions';
import {
  selectDefaultLang,
} from '@rentivo/gatsby-core/src/containers/LanguageProvider/selectors';
import { selectPageContextLang } from '@rentivo/gatsby-core/src/selectors/pageContext';
import { withPageContext } from '@rentivo/gatsby-core/src/context/PageContext';
import {
  commercePricingSelectorEmulator,
  selectCommerceCart, selectCommerceChargeCurrency, selectCommerceCurrentStep, selectCommerceData,
  selectCommerceIsValidCartFromCart, selectPropertyFromCart
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/selectors';
import { createDeepEqualSelector } from '@rentivo/gatsby-core/src/selectors/utils';
import { withStore } from '@rentivo/gatsby-core/src/context/StoreContext';
import isEmpty from 'lodash/isEmpty';
import { selectPricingDataReservationType } from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors';

CommerceProvider.defaultProps = {
  customCheckoutPaths: {}
};

const commercePricingSelector = createSelector(
  selectPricingDataReservationType,
  (reservationType) => ({
    reservationType
  })
);

// This uses to props > the page context, so you can pass a property in
const pageContextSelector = createSelector(
  selectPageContextLang,
  (locale) => ({
    locale
  })
);

const mapStateToProps = (state, { locale, children }) => createDeepEqualSelector(
  selectRoutePathsConfig,
  selectDefaultLang,
  selectCommerceCart,
  selectCommerceChargeCurrency,
  selectCommerceData,
  selectCommerceCurrentStep,
  (routePaths, defaultLocale, cart, chargeCurrency, reservationData, currentStep) => {
    const { steps, sanitizedChildRoutes, invalidComponent } = getStepsAndSanitizeChildren(children, locale, defaultLocale, routePaths);
    const isSuccessPage = (steps && steps.length && currentStep && steps[currentStep].success === true);
    return {
      commerce: {
        currentStep,
        steps
      },
      sanitizedChildRoutes,
      invalidComponent,
      isValidCart: (selectCommerceIsValidCartFromCart(cart) || (!isEmpty(reservationData) && isSuccessPage)), // If cart has property which is available OR on success page with data from reservation
      property: selectPropertyFromCart(cart),
      chargeCurrency
    };
  }
);

const mapDispatchToProps = (dispatch) => ({
  setDefaultValues: (params) => dispatch(startSetDefaultValues(params)),
  setStep: (stepIndex) => dispatch(setStep(stepIndex))
});

const enhance = compose(
  withStore,
  withPageContext(pageContextSelector),
  connect(commercePricingSelectorEmulator(commercePricingSelector)),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    setDefaultValues: ({setDefaultValues, commerce, chargeCurrency, reservationType}) => payload => setDefaultValues({...payload, ...commerce, chargeCurrency, reservationType}),
    handleRouteChange: ({commerce, setStep}) => ({ pathname }) => {
      let { steps = [] } = commerce || {};
      let stepIndex = null;
      steps.forEach((step, i) => {
        if(step.path && step.path === pathname) stepIndex = i;
      });
      if(stepIndex !== null) {
        setStep(stepIndex);
      }
    }
  })
);

export default enhance(CommerceProvider);

// Pricing guests & dates are not saved on a refresh, so pricing widget doesn't know what to do...
// Every refresh of the pricing needs to update the cart (if in checkout)...
// If a pricing request suddenly comes back invalid, need to invalidate products in cart...
// Need a selector to see if cart is invalid... and render something different
// Make buttons invalid if pricing is loading...

// Remove fields that are not needed in checkout, including actions & inputs
// Automatically show extras (open)
// Add new components, like property overview & guests & dates overview

// TODO: Make pricing widget mobile friendly on property page with new component fixed to the bottom
// TODO: Make the checkout pricing widget mobile friendly by removing box...

// TODO: Do review process... just display components


