import React, { useCallback } from 'react';
import { Heading, Text } from '@chakra-ui/react';
import StepLayout from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/StepLayout/component';
import { Button } from '@rentivo/gatsby-core/index';
import Form from '@rentivo/gatsby-core/src/components/forms/Form';
import {
  StyledStepPageFooter,
  StyledStepPageHeader,
  StyledStepPageBody
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/styles';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/CustomerInfo/messages';
import commerceMessages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/messages';
import CustomerInfoForm from '@rentivo/gatsby-core/src/components/forms/CustomerInfoForm';
import { useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';

export const initialCustomerInfoValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  country: ''
};

const CustomerInfoComponent = ({ hasNextStep, hasPrevStep, nextStep, prevStep, isPricingLoading, info, setCustomerInfo }) => {
  const defaultValues = {
    ...initialCustomerInfoValues,
    ...info
  };

  const form = useForm({defaultValues});

  const onSubmit = useCallback(debounce((formData) => {
    setCustomerInfo(formData);
    nextStep();
  }, 350), []);

  const onError = async (errors, e) => {
    e.preventDefault();
    console.log(errors);
  };

  return (
    <Form onSubmit={onSubmit} onError={onError} form={form}>
      <StepLayout>
        <StyledStepPageHeader>
          <Heading as="h1"><FormattedMessage {...messages.title}/></Heading>
          <Text variant="light"><FormattedMessage {...messages.description}/></Text>
        </StyledStepPageHeader>

        <StyledStepPageBody>
          <CustomerInfoForm
            form={form}
            defaultValues={info}
          />
        </StyledStepPageBody>

        <StyledStepPageFooter>
          {hasPrevStep && <Button useDebounce disabled={isPricingLoading} onClick={() => prevStep()} className="prev"><FormattedMessage {...commerceMessages.prevButtonLabel}/></Button>}
          {hasNextStep && <Button type="primary" disabled={isPricingLoading} htmlType="submit" className="next" ml="auto"><FormattedMessage {...commerceMessages.nextButtonLabel}/></Button>}
        </StyledStepPageFooter>
      </StepLayout>
    </Form>
  )
};

export default CustomerInfoComponent;

