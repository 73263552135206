import React from 'react';
import ConfirmInstantBook from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/ConfirmInstantBook/component';
import { compose } from 'redux';
import withSteps from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/withSteps';
import withCustomer from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/withCustomer';
import { withTheme } from '@emotion/react';
import withCart from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/withCart';

const enhance = compose(
  withSteps,
  withCustomer,
  withCart(),
  withTheme
);

export default enhance(ConfirmInstantBook);
