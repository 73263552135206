import React from 'react';
import { RESERVATION_TYPE_REQUEST_TO_BOOK } from '@rentivo/gatsby-core/src/constants/lycanConstants';
import SuccessInstantBook from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Success/components/SuccessInstantBook';
import { PropertyProvider } from '@rentivo/gatsby-core/src/context/PropertyContext';
import SuccessRequestToBook from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Success/components/SuccessRequestToBook';

const SuccessComponent = ({reservationType, propertyInData}) => {

  if(!reservationType) return null;

  return (
    <PropertyProvider property={propertyInData}>
      {reservationType === RESERVATION_TYPE_REQUEST_TO_BOOK ? <SuccessRequestToBook/> : <SuccessInstantBook/>}
    </PropertyProvider>
  );
};

export default SuccessComponent;

