import { defineMessages } from 'react-intl';

export const scope = 'containers.CommerceProvider.NotFound';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: '404, this page does not exist',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Sorry the page you are trying to access, does not exist.',
  }
});
