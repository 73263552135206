import React from 'react'
import { chakra, Box, Flex, VStack } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from 'react-intl'
import messages from '@rentivo/gatsby-core/src/components/property/PropertyCancellation/messages'
import { faKey } from '@fortawesome/pro-regular-svg-icons/faKey'
import {
  getDateFromStartDate,
  getRefundMessage,
} from '@rentivo/gatsby-core/src/components/property/PropertyCancellation/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { FORMAT_DATE_SHORT_OPTIONS } from '@rentivo/gatsby-core/src/constants/dates'
import PropertyComponentTitle from '@rentivo/gatsby-core/src/components/property/PropertyComponentTitle'
import AwesomeIcon from '@rentivo/gatsby-core/src/components/ui/AwesomeIcon'

const PolicyItemDescriptor = ({
  policyItem,
  prevPolicyItem,
  isLastPolicyItem,
  daysToCheckin,
  startDate,
}) => {
  const { formatMessage, formatDate } = useIntl()
  const { refund, refundedPercentage } = getRefundMessage(
    formatMessage,
    policyItem
  )
  const { isActive, graceHours } = policyItem

  let messageProps = {
    graceHours: policyItem.graceHours,
    daysBeforeArrival: policyItem.daysBeforeArrival,
    prevDaysBeforeArrival: prevPolicyItem
      ? prevPolicyItem.daysBeforeArrival
      : '',
    refund,
    strong: children => <strong>{children}</strong>,
  }
  let policyMessage = formatMessage(messages.refundRule, messageProps)
  if (isLastPolicyItem) {
    policyMessage =
      refundedPercentage === 0
        ? formatMessage(messages.refundRuleLessThanLast, messageProps)
        : formatMessage(messages.refundRuleBetween, messageProps)
  }

  let graceHoursMessage = null
  if (graceHours) {
    graceHoursMessage = formatMessage(messages.graceHoursMessage, messageProps)
  }

  if (startDate) {
    messageProps = {
      ...messageProps,
      prevDate: prevPolicyItem
        ? getDateFromStartDate(
            formatDate,
            prevPolicyItem,
            startDate,
            FORMAT_DATE_SHORT_OPTIONS
          )
        : '',
      date: getDateFromStartDate(
        formatDate,
        policyItem,
        startDate,
        FORMAT_DATE_SHORT_OPTIONS
      ),
    }
    policyMessage = formatMessage(messages.refundRuleDate, messageProps)
    if (isLastPolicyItem) {
      policyMessage =
        refundedPercentage === 0 && messageProps.prevDate
          ? formatMessage(messages.refundRuleLessThanLastDate, messageProps)
          : messageProps.prevDate && messageProps.date
          ? formatMessage(messages.refundRuleBetweenDate, messageProps)
          : formatMessage(messages.noRefund, messageProps)
    }
  }

  return (
    <Box
      w="100%"
      color={!daysToCheckin ? 'text' : isActive ? 'text' : 'textLight'}
    >
      <AwesomeIcon
        mr={1}
        width="24px!important"
        color={isActive ? 'primary.500' : 'textLight'}
        icon={!daysToCheckin ? faCheck : isActive ? faCheck : faTimes}
      />
      {policyMessage} {graceHoursMessage}
    </Box>
  )
}

const timelineSepLabelProps = {
  className: 'timeline-sep--label',
  w: 'max-content',
  direction: 'column',
  align: 'center',
  fontSize: 'xs',
  lineHeight: 'tall',
  color: 'textLight',
  mt: '12px',
  maxW: '133px',
  minW: '100px',
  textAlign: 'center',
}

const PolicyItemTimelineItem = ({
  policyItem,
  isLastPolicyItem,
  startDate,
}) => {
  const { formatMessage, formatDate } = useIntl()
  const { refund } = getRefundMessage(formatMessage, policyItem)
  const date = getDateFromStartDate(formatDate, policyItem, startDate)
  const { isActive, graceHours } = policyItem

  let graceHoursMessage = null
  if (graceHours) {
    graceHoursMessage = formatMessage(messages.graceHoursMessage, {
      graceHours,
    })
  }

  return (
    <>
      <Flex
        className="timeline-period"
        direction="column"
        align="center"
        grow={1}
      >
        <chakra.div
          className="timeline-period--label"
          borderRadius="md"
          bg={isActive ? 'primary.500' : 'gray.200'}
          fontSize="xs"
          minH="16px"
          color={isActive ? 'white' : 'textLight'}
          fontWeight="semibold"
          pt={1}
          pb={1}
          pl={3}
          pr={3}
          mb={3}
        >
          {isActive && <AwesomeIcon mr={1} icon={faCheck} />} {refund}
        </chakra.div>
        <chakra.div
          className="timeline-period--line"
          h="4px"
          borderRadius="3px"
          bg={isActive ? 'primary.500' : 'gray.200'}
          w="100%"
        />
      </Flex>
      <Flex
        className="timeline-sep"
        w="9px"
        h="9px"
        direction="column"
        align="center"
        zIndex={1}
      >
        {isLastPolicyItem ? (
          <>
            <AwesomeIcon
              className="timeline-sep--icon"
              icon={faKey}
              color="primary.500"
              bg="white"
            />
            <Flex {...timelineSepLabelProps}>
              <span>
                {date ? date : formatMessage(messages.checkin)}{' '}
                {graceHoursMessage}
              </span>
            </Flex>
          </>
        ) : (
          <>
            <chakra.div
              className="timeline-sep--circle"
              w="7px"
              h="7px"
              border="3px solid"
              borderColor="white"
              p="2px"
              borderRadius="100%"
              bg="primary.500"
              boxSizing="content-box"
              zIndex={1}
              mt="-1px"
            >
              <chakra.div
                className="timeline-sep--circle"
                w="7px"
                h="7px"
                borderColor="white"
                borderRadius="100%"
              />
            </chakra.div>

            <Flex {...timelineSepLabelProps}>
              <span>
                {date
                  ? date
                  : formatMessage(messages.daysBeforeCheckin, {
                      daysBeforeArrival: policyItem.daysBeforeArrival,
                    })}{' '}
                {graceHoursMessage}
              </span>
            </Flex>
          </>
        )}
      </Flex>
    </>
  )
}

const PropertyCancellationComponent = ({
  wrapper: Wrapper,
  policyItems,
  showTitle,
  title,
  className,
  daysToCheckin,
  startDate,
}) => {
  if (!policyItems || !policyItems.length) return null

  return (
    <Wrapper>
      <chakra.div className={className}>
        {showTitle && (
          <PropertyComponentTitle>
            {title ? title : <FormattedMessage {...messages.title} />}
          </PropertyComponentTitle>
        )}
        <VStack spacing={1} color="textLight" className="descriptors">
          {policyItems.map((policyItem, i) => (
            <PolicyItemDescriptor
              key={i}
              daysToCheckin={daysToCheckin}
              startDate={startDate}
              prevPolicyItem={policyItems[i - 1]}
              isLastPolicyItem={i + 1 === policyItems.length}
              policyItem={policyItem}
            />
          ))}
        </VStack>

        <chakra.div
          className="timeline"
          display={{ base: 'none', md: 'block' }}
        >
          <Flex
            justify="space-between"
            mt={16}
            mb={16}
            pr={12}
            align="flex-end"
          >
            {policyItems.map((policyItem, i) => (
              <PolicyItemTimelineItem
                key={i}
                daysToCheckin={daysToCheckin}
                startDate={startDate}
                prevPolicyItem={policyItems[i - 1]}
                isLastPolicyItem={i + 1 === policyItems.length}
                policyItem={policyItem}
              />
            ))}
          </Flex>
        </chakra.div>
      </chakra.div>
    </Wrapper>
  )
}

export default PropertyCancellationComponent
