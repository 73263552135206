import React from 'react';
import Price from '@rentivo/gatsby-core/src/components/generic/Price';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/checkout/CartBreakdown/messages';
import {
  StyledCartBreakdownTable
} from '@rentivo/gatsby-core/src/components/checkout/CartBreakdown/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';
import { Heading, Box, chakra, useBreakpointValue } from '@chakra-ui/react';
import AwesomeIcon from '@rentivo/gatsby-core/src/components/ui/AwesomeIcon';

const CartBreakdownComponent = ({
  currentCurrency,
  cart,
  chargeCurrency,
  chargeExchangeRate,
  cartTotalDueToday,
  cartTotalChargeDueToday,
  cartTotal,
  noNights,
  guests,
  pets,
  startDate,
  endDate,
  showTitle,
  success,
  className,
  styleProps = {}
}) => {
  const { formatDate } = useIntl();
  startDate = (startDate) ? formatDate(startDate, {month: 'short', day: '2-digit', timeZone: 'UTC'}) : null;
  endDate = (endDate) ? formatDate(endDate, {month: 'short', day: '2-digit', timeZone: 'UTC'}) : null;

  const priceProps = {
    currency: chargeCurrency,
    fractionDigits: 2,
    maximumFractionDigits: 2,
    periodSeparator: '',
    period: '',
    showZero: true
  };

  const totalColSpan = useBreakpointValue({ base: 1, lg: 2 });

  return (
    <Box className={className} {...styleProps}>
      {showTitle && <Heading as="h3" mb={3}><FormattedMessage {...messages.title}/></Heading>}
      {cart.length && (
        <StyledCartBreakdownTable>
          <table>
            <thead>
              <tr>
                <th className="product"><FormattedMessage {...messages.tableItemLabel}/></th>
                <th><FormattedMessage {...messages.tableQuantityLabel}/></th>
                <chakra.th display={{base: 'none', lg: 'table-cell'}}><FormattedMessage {...messages.tableTotalPriceLabel}/></chakra.th>
                <th>{success ? <FormattedMessage {...messages.tablePaidTodayLabel}/> : <FormattedMessage {...messages.tableDueTodayLabel}/>}</th>
              </tr>
            </thead>
            <tbody>
            {cart.map((item, i) => (
              <tr key={i}>
                <td className="product">
                  {item.name}
                  {item.property && (
                    <chakra.div fontSize="xs" display="flex" flexWrap="wrap">
                      <chakra.div mr={3}><AwesomeIcon icon={faUsers}/> <FormattedMessage {...messages.guestsText} values={{guestsCount: guests, petsCount: pets}} /></chakra.div>
                      <chakra.div><AwesomeIcon icon={faCalendar}/> <FormattedMessage {...messages.datesText} values={{startDate, endDate}} /></chakra.div>
                    </chakra.div>
                  )}
                </td>
                <td>
                  {item.property ? (
                    <><FormattedMessage {...messages.tablePropertyQuantityLabel} values={{nightsCount: noNights}}/></>
                  ) : (
                    <><FormattedMessage {...messages.tableProductQuantityLabel} values={{quantityCount: item.quantity}}/></>
                  )}
                </td>
                <chakra.td display={{base: 'none', lg: 'table-cell'}} fontSize={{base: 'sm', lg: 'md'}}>
                  <Price price={item.fullPrice} {...priceProps} />
                </chakra.td>
                <chakra.td fontSize={{base: 'sm', lg: 'md'}}>
                  <Price price={item.price} {...priceProps} />
                </chakra.td>
              </tr>
            ))}
            </tbody>
            <tfoot>
              <tr>
                <chakra.th colSpan={totalColSpan} className="product"><FormattedMessage {...messages.tableFootTotalLabel}/></chakra.th>
                <chakra.th><Price price={cartTotal} {...priceProps} /></chakra.th>
                <th><Price price={cartTotalDueToday} {...priceProps} /></th>
              </tr>
            </tfoot>
          </table>
        </StyledCartBreakdownTable>
      )}

    </Box>
  )
};

export default CartBreakdownComponent;
