import { defineMessages } from 'react-intl';

export const scope = 'containers.CommerceProvider.Confirm';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Your details',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Please enter your contact & billing details',
  },
  stepName: {
    id: `${scope}.step.name`,
    defaultMessage: 'Confirm',
  },
  stepDescription: {
    id: `${scope}.step.description`,
    defaultMessage: 'Confirm your stay',
  },
  conversionWarningText: {
    id: `${scope}.conversionWarning.text`,
    defaultMessage: 'This property accepts payments in {chargeCurrency}. We will transact this payment due today as {chargeAmount} '
  },
  conversionWarningWithMerchantText: {
    id: `${scope}.conversionWarningWithMerchant.text`,
    defaultMessage: ' and will show as {merchantName} on your card statement'
  },
  exchangeTooltipText: {
    id: `${scope}.exchangeTooltip.text`,
    defaultMessage: 'Converted from {from} at an exchange rate of {exchangeRate}',
  }
});
