import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import StepLayout from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/StepLayout/component'
import {
  Alert,
  AlertIcon,
  Heading,
  Text,
  Flex,
  Stack,
  Radio,
  Select,
  Box,
} from '@chakra-ui/react'
import { Button } from '@rentivo/gatsby-core/index'
import Form from '@rentivo/gatsby-core/src/components/forms/Form'
import { INVALIDATE_RESERVATION } from '@rentivo/gatsby-core/src/containers/CommerceProvider/constants'
import {
  StyledStepPageHeader,
  StyledStepPageBody,
  StyledStepPageFooter,
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/styles'
import { FormattedMessage, useIntl } from 'react-intl'
import messages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/ConfirmInstantBook/messages'
import confirmMessages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/messages'
import paymentFormMessages from '@rentivo/gatsby-core/src/components/forms/PaymentForm/messages'
import commerceMessages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/messages'
import PaymentForm from '@rentivo/gatsby-core/src/components/forms/PaymentForm'
import { document, window } from 'browser-monads'
import { isFunction } from 'lodash'
import Loader from '@rentivo/gatsby-core/src/components/generic/Loader'
import PaymentCards from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/PaymentCards'
import CartBreakdown from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/CartBreakdown'
import Info from '@rentivo/gatsby-core/src/components/ui/Info/component'
import { StyledStepPageConfirmInstantBookFooterConversionWarning } from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/ConfirmInstantBook/styles'
import { slugToTitle } from '@rentivo/gatsby-core/src/utils/strings'
import Checkboxes from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/Checkboxes'
import { useForm } from 'react-hook-form'
import { initialCustomerInfoValues } from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/CustomerInfo/component'
import { usePaymentInputStyles } from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/ConfirmInstantBook/hooks'
import ReservationErrors from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/ReservationErrors'
import debounce from 'lodash/debounce'
import { startReceiveData } from '../../../../actions'
import { startRequestPricingData } from '@rentivo/gatsby-core/src/containers/PricingProvider/actions'

const currencyDisplayConfig = currency => ({
  currency,
  useGrouping: true,
  style: 'currency',
  currencyDisplay: 'symbol',
})

const ConfirmInstantBookComponent = ({
  hasNextStep,
  hasPrevStep,
  nextStep,
  prevStep,
  theme,
  isPricingLoading,
  info,
  billing,
  setCustomerBilling,
  spreedlyEnvironment,
  currentCurrency,
  cart,
  chargeCurrency,
  chargeExchangeRate,
  cartTotalDueToday,
  cartTotalChargeDueToday,
  cartTotal,
  merchantName,
  supportedCards,
  pairings,
  createInstantBookReservation,
  reservationData,
  reservationLoading,
  reservationDidInvalidate,
  reservationError,
  reservationIsThreeDSChallenge,
  transactionToken,
  potentialData,
  reducedPaymentGateways,
  showGateways,
  cards,
  getExchangedCurrency,
}) => {
  const dispatch = useDispatch()

  // spreedlyEnvironment = 'DddR2KmU8Sr9jbOknheveJWIiU7' // CLEANUP

  const defaultValues = {
    ...initialCustomerInfoValues,
    expiryYear: '',
    expiryMonth: '',
    fullName: '',
    termsAndConditions: false,
    marketingOptIn: false,
  }
  const hiddenIframeRef = useRef()
  const threeDSChallengeRef = useRef()
  const form = useForm({ defaultValues })
  const { trigger, getValues } = form
  const paymentInputStyles = usePaymentInputStyles()
  const { formatMessage, formatNumber } = useIntl()
  const [loading, setLoading] = useState(false)
  const lifecycle = useRef(null)
  console.log(
    'auto-select',
    reducedPaymentGateways.find(
      g => g.isDefault || g.currency === currentCurrency
    )
  )
  const [pgId, setPgId] = useState(
    reducedPaymentGateways && reducedPaymentGateways.length > 0
      ? `${
          reducedPaymentGateways.find(
            g => g.isDefault || g.currency === currentCurrency
          )?.id
        }`
      : null
  )

  const onGatewayChange = useCallback(
    pgId => {
      setPgId(pgId)
      const gateway = reducedPaymentGateways.find(g => g.id === pgId)
      if (gateway && gateway.currency) {
        dispatch(startRequestPricingData(true, gateway.currency))
      }
    },
    [reducedPaymentGateways]
  )

  useEffect(() => {
    const gateway = reducedPaymentGateways.find(g => g.id === pgId)
      if (gateway && gateway.currency) {
        dispatch(startRequestPricingData(true, gateway.currency))
      }
  }, []);

  const [useDifferentBillingAddress, setUseDifferentBillingAddress] = useState(
    !isEmpty(billing)
  )
  window.useDifferentBillingAddress =
    window.useDifferentBillingAddress !== undefined
      ? window.useDifferentBillingAddress
      : !isEmpty(billing)
  const [spreedlyInjected, setSpreedlyInjected] = useState(false)
  const [spreedlyInitialized, setSpreedlyInitialized] = useState(false)
  const [hasSpreedlyErrors, setHasSpreedlyErrors] = useState(false)
  const [spreedlyErrors, setSpreedlyErrors] = useState([])

  useEffect(() => {
    if (
      reservationIsThreeDSChallenge &&
      spreedlyInitialized &&
      transactionToken
    ) {
      lifecycle.current = new Spreedly.ThreeDS.Lifecycle({
        environmentKey: spreedlyEnvironment,
        // // The environmentKey field is required, but if omitted, you will receive a console warning message and the transaction will still succeed.
        hiddenIframeLocation: hiddenIframeRef.current.id,
        // // The DOM node that you'd like to inject hidden iframes
        challengeIframeLocation: threeDSChallengeRef.current.id,
        // // The DOM node that you'd like to inject the challenge flow
        transactionToken: transactionToken,
        // // The token for the transaction - used to poll for state
        // challengeIframeClasses: '...', (optional)
      })

      lifecycle.current.start()
    }
  }, [
    reservationIsThreeDSChallenge,
    spreedlyInitialized,
    spreedlyEnvironment,
    transactionToken,
  ])

  const cartTotalDueTodayInWebsiteCurrency = formatNumber(
    getExchangedCurrency(cartTotalDueToday, 2, chargeCurrency, currentCurrency),
    currencyDisplayConfig(currentCurrency)
  )
  const cartTotalDueTodayFormatted = formatNumber(
    cartTotalDueToday,
    currencyDisplayConfig(currentCurrency)
  )
  const cartTotalChargeDueTodayFormatted = formatNumber(
    cartTotalChargeDueToday,
    currencyDisplayConfig(chargeCurrency)
  )

  const handleToggleBillingAddress = () => {
    window.useDifferentBillingAddress = !useDifferentBillingAddress
    setUseDifferentBillingAddress(!useDifferentBillingAddress)
  }

  const handleOnFinish = useCallback(
    debounce(e => {
      e.preventDefault()
      setLoading(true)
      setHasSpreedlyErrors(false)
      setSpreedlyErrors([])

      Spreedly.validate()
    }, 350),
    []
  )

  useEffect(() => {
    // Mount
    if (!spreedlyInjected) {
      setLoading(true)
      const asyncScript = document.createElement('script')
      asyncScript.type = 'text/javascript'
      asyncScript.src = 'https://core.spreedly.com/iframe/iframe-v1.min.js'
      asyncScript.id = 'spreedly-script'
      document.head.appendChild(asyncScript)

      asyncScript.onload = () => {
        setSpreedlyInjected(true)
        initializeSpreedly()
      }
    }
    return () => {
      // Unmount
      const script = document.getElementById('spreedly-script')
      if (script) script.remove()
    }
  }, [])

  const initializeSpreedly = useCallback(() => {
    setLoading(true)

    Spreedly.init(spreedlyEnvironment, {
      numberEl: 'spreedly-number',
      cvvEl: 'spreedly-cvv',
    })

    Spreedly.on('ready', () => {
      Spreedly.setFieldType('number', 'text')
      Spreedly.setNumberFormat('prettyFormat')
      Spreedly.setStyle('number', paymentInputStyles)
      Spreedly.setStyle('cvv', paymentInputStyles)
      setSpreedlyInitialized(true)
      setLoading(false)
    })

    Spreedly.on('validation', async inputProperties => {
      let errors = []

      if (!inputProperties['validNumber']) {
        errors.push(
          formatMessage(paymentFormMessages.inputCardNumberErrorInvalid)
        )
        const cardType = inputProperties['cardType']
        const validCards = cards.filter(
          card =>
            card && cardType && card.toLowerCase() === cardType.toLowerCase()
        )
        if (validCards.length === 0 && cardType) {
          errors.push(
            formatMessage(paymentFormMessages.inputCardNumberErrorUnsupported, {
              card: slugToTitle(cardType),
            })
          )
        }
      }

      if (!inputProperties['validCvv']) {
        errors.push(formatMessage(paymentFormMessages.inputCvvErrorInvalid))
      }

      if (errors.length > 0) {
        setHasSpreedlyErrors(true)
        setSpreedlyErrors(errors)

        // We do not want to submit the form after success as there are spreedly errors
        await validateNormalFormValuesAndSubmit(null)
      } else {
        // The form is VALID, we can perform validation on the reset
        await validateNormalFormValuesAndSubmit(values => {
          setCustomerBilling(values) // Set billing deets
          tokenizeCardAndSubmit(values) // Tokenize card
        })
      }
    })

    Spreedly.on('errors', errors => {
      let errorMessages = []
      for (let i = 0; i < errors.length; i++) {
        let error = errors[i]
        errorMessages.push(error.message)
      }

      setHasSpreedlyErrors(true)
      setSpreedlyErrors(errorMessages)
      completeFormProcessing()
    })

    Spreedly.on('paymentMethod', (token, data) => {
      const browserInfo = Spreedly.ThreeDS.serialize()
      handleCreateReservation(token, data, browserInfo)
    })

    //Define:
    const statusUpdates = function(event) {
      if (event.action === 'succeeded') {
        if (potentialData) {
          dispatch(startReceiveData(potentialData))
        } else {
          dispatch({
            type: INVALIDATE_RESERVATION,
            error: `Something went wrong.`,
          })
        }
      } else if (event.action === 'error') {
        dispatch({
          type: INVALIDATE_RESERVATION,
          error: `Payment challenge failed.`,
        })
      } else if (event.action === 'challenge') {
        // Show your modal containing the div provided in `challengeIframeLocation` when
        // creating the lifecycle event.
        //
        // Example HTML on your page:
        //
        // <head>
        //   <style>
        //     .hidden {
        //       display: none;
        //     }
        //
        //     #challenge-modal {
        //       <!-- style your modal here -->
        //     }
        //   </style>
        // </head>
        // <body>
        //   <div id="device-fingerprint" class="hidden">
        //     <!-- Spreedly injects content into this div,
        //          do not nest the challenge div inside of it -->
        //   </div>
        //   <div id="challenge-modal" class="hidden">
        //     <div id="challenge">
        //     </div>
        //   </div>
        // </body>
        //
        //  Example lifecycle object from step 3:
        //
        //  var lifecycle = new Spreedly.ThreeDS.Lifecycle({
        //    hiddenIframeLocation: 'device-fingerprint',
        //    challengeIframeLocation: 'challenge',
        //    ...
        //  })
        //
        //  and then we show the challenge-modal
        //
        document.getElementById('challenge-modal').classList.remove('hidden')
      } else if (event.action === 'trigger-completion') {
        // 1. make a request to your backend to do an authenticated call to Spreedly to complete the request
        //    The completion call is `https://core.spreedly.com/v1/transactions/[transaction-token]/complete.json (or .xml)`
        // 2a. if the transaction is marked as "succeeded" finish your checkout and redirect to success page
        // 2b. if the transaction is marked "pending" you'll need to call finalize `event.finalize(transaction)` with the transaction data from the authenticated completion call.
      }
    }

    //Set:
    Spreedly.on('3ds:status', statusUpdates)
  }, [useDifferentBillingAddress, theme, potentialData])

  const validateNormalFormValuesAndSubmit = useCallback(
    async successCallback => {
      try {
        const result = await trigger()
        if (result) {
          let values = await getValues()

          // If using same billing details from previous page, need to copy across address information and override values from this form...
          if (!window.useDifferentBillingAddress) {
            values = {
              ...values,
              ...info,
            }
          }

          if (isFunction(successCallback)) {
            successCallback(values)
          } else {
            completeFormProcessing()
          }
        } else {
          completeFormProcessing()
        }
      } catch (e) {
        completeFormProcessing()
      }
    },
    [useDifferentBillingAddress]
  )

  const tokenizeCardAndSubmit = useCallback(cardData => {
    // This will fire off another spreedly event depending
    Spreedly.tokenizeCreditCard({
      full_name: cardData['fullName'],
      month: cardData['expiryMonth'],
      year: cardData['expiryYear'],
      zip: cardData['zip'] ? cardData['zip'] : null,
      address1: cardData['addressLine1'] ? cardData['addressLine1'] : null,
      address2: cardData['addressLine2'] ? cardData['addressLine2'] : null,
      city: cardData['city'] ? cardData['city'] : null,
      state: cardData['state'] ? cardData['state'] : null,
      country: cardData['country'] ? cardData['country'] : null,
      email: info.email,
    })
  }, [])

  const completeFormProcessing = () => {
    setLoading(false)
  }

  const [pg] = reducedPaymentGateways.filter(rpg => rpg.id === pgId)

  const handleCreateReservation = (token, data, browserInfo) => {
    createInstantBookReservation(token, browserInfo, pg)
    completeFormProcessing()
  }

  console.log('debug', {
    showGateways,
    pairings,
    reducedPaymentGateways,
    pgId,
    pg,
  })

  return (
    <Form form={form}>
      <StepLayout>
        <StyledStepPageHeader>
          <Heading as="h1">
            <FormattedMessage {...messages.title} />
          </Heading>
          <Text variant="light">
            <FormattedMessage {...messages.description} />
          </Text>
        </StyledStepPageHeader>

        <div id="hidden-iframe" ref={hiddenIframeRef}></div>
        <div id="three-ds-challenge" ref={threeDSChallengeRef}></div>

        <>
          <StyledStepPageBody>
            {(loading || reservationLoading) && <Loader />}
            <CartBreakdown />
            <PaymentForm
              form={form}
              defaultValues={{ ...info, ...billing }}
              info={info}
              billing={billing}
              useDifferentBillingAddress={useDifferentBillingAddress}
              handleToggleBillingAddress={handleToggleBillingAddress}
              loading={loading || reservationLoading}
              spreedlyInjected={spreedlyInjected}
              spreedlyInitialize={spreedlyInitialized}
              hasSpreedlyErrors={hasSpreedlyErrors}
              spreedlyErrors={spreedlyErrors}
              spreedlyEnvironment={spreedlyEnvironment}
              cardsComponent={PaymentCards}
              checkboxesComponent={Checkboxes}
            />

            <ReservationErrors />
          </StyledStepPageBody>

          <StyledStepPageFooter display="block">
            <Flex
              justify="space-between"
              direction={{ base: 'column', md: 'row' }}
            >
              {hasPrevStep && (
                <Button
                  useDebounce
                  order={{ base: 1, md: 0 }}
                  disabled={isPricingLoading || loading || reservationLoading}
                  onClick={() => prevStep()}
                  className="prev"
                >
                  <FormattedMessage {...commerceMessages.prevButtonLabel} />
                </Button>
              )}
              {hasNextStep && (
                <Stack>
                  {pgId &&
                    showGateways &&
                    reducedPaymentGateways &&
                    reducedPaymentGateways.length > 0 && (
                      <Select
                        defaultValue="GBP"
                        size="sm"
                        value={pgId}
                        width="300px"
                        onChange={e => onGatewayChange(e.target.value)}
                      >
                        {reducedPaymentGateways.map(pg => (
                          <option key={pg.id} value={pg.id}>
                            Pay in {pg.currency} - {pg.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  <Button
                    order={{ base: 0, md: 1 }}
                    mb={{ base: 2, md: 0 }}
                    disabled={isPricingLoading || loading || reservationLoading}
                    onClick={handleOnFinish}
                    className="next"
                    type="primary"
                  >
                    <FormattedMessage
                      {...messages.confirmButtonLabel}
                      values={{ totalDue: cartTotalChargeDueTodayFormatted }}
                    />
                  </Button>
                </Stack>
              )}
            </Flex>
            <StyledStepPageConfirmInstantBookFooterConversionWarning>
              <div>
                <FormattedMessage
                  {...confirmMessages.conversionWarningText}
                  values={{
                    chargeCurrency,
                    amount: cartTotalDueTodayFormatted,
                    chargeAmount: cartTotalChargeDueTodayFormatted,
                  }}
                />
                {chargeExchangeRate !== 1 && (
                  <Info
                    title={formatMessage(confirmMessages.exchangeTooltipText, {
                      from: cartTotalDueTodayFormatted,
                      exchangeRate: chargeExchangeRate,
                    })}
                  />
                )}
                {merchantName && (
                  <FormattedMessage
                    {...confirmMessages.conversionWarningWithMerchantText}
                    values={{ merchantName }}
                  />
                )}
              </div>
            </StyledStepPageConfirmInstantBookFooterConversionWarning>
          </StyledStepPageFooter>
        </>
      </StepLayout>
    </Form>
  )
}

export default ConfirmInstantBookComponent
