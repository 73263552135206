import { chakra } from '@chakra-ui/react';

const headerBaseStyles = {
  pr: { base: 0, md: 8 },
  pl: { base: 0, md: 8 },
  pt: { base: 4, md: 6 },
  pb: { base: 4, md: 6 },
  position: 'relative'
};

export const StyledStepPageHeader = chakra('div', {
  baseStyle: {
    ...headerBaseStyles,
    borderBottom: '1px solid',
    borderColor: 'gray.200'
  }
});

export const StyledStepPageFooter = chakra('div', {
  baseStyle: {
    ...headerBaseStyles,
    borderTop: '1px solid',
    borderColor: 'gray.200',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});

export const StyledStepPageBody = chakra('div', {
  baseStyle: {
    ...headerBaseStyles,
    pt: 10,
    pb: 10
  }
});
