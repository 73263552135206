import React from 'react';
import Confirm from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/component';
import { compose } from 'redux';
import messages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/messages';
import { FormattedMessage } from 'react-intl';
import {
  CHECKOUT_STEP_CONFIRM_PATH
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/constants';
import { createSelector } from 'reselect';
import { selectCommerceReservationType } from '@rentivo/gatsby-core/src/containers/CommerceProvider/selectors';
import { connect } from 'react-redux';

const mapStateToProps = createSelector(
  selectCommerceReservationType,
  (reservationType) => {
    return {
      reservationType
    }
  }
);

const enhance = compose(
  connect(mapStateToProps)
);

Confirm.defaultProps = {
  path: CHECKOUT_STEP_CONFIRM_PATH,
  name: <FormattedMessage {...messages.stepName}/>,
  description: <FormattedMessage {...messages.stepDescription}/>
};

export default enhance(Confirm);
