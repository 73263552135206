import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Review/components/ChangeGuests/messages';
import GuestsPickerPopover from '@rentivo/gatsby-core/src/containers/PricingProvider/components/GuestsPickerPopover';
import { Heading, chakra } from '@chakra-ui/react';

const ChangeGuestsComponent = ({className}) => {
  return (
    <chakra.div className={className}>
      <Heading as="h3" mb={3}><FormattedMessage {...messages.title} /></Heading>
      <chakra.div w="268px">
        <GuestsPickerPopover title={null} tooltip={false}/>
      </chakra.div>
    </chakra.div>
  );
};

export default ChangeGuestsComponent;

