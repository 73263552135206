import React from 'react';
import HeaderNavbar from '@rentivo/gatsby-core/src/components/ui/HeaderNavbar';
import TopBar from '@rentivo/gatsby-core/src/components/ui/TopBar';
import { chakra } from '@chakra-ui/react';

const CheckoutLayoutComponent = ({children, topBar, menuItems}) => {
  return (
    <chakra.div id="page-layout" className="layout">
      {(topBar && topBar.length > 0) && (
        <TopBar blocks={topBar}/>
      )}
      <HeaderNavbar {...menuItems}/>
      <chakra.div mt={12} mb={12} className="layout-content">
        {children}
      </chakra.div>
    </chakra.div>
  )
};

export default CheckoutLayoutComponent;
