import { defineMessages } from 'react-intl';

export const scope = 'containers.CommerceProvider.ReservationBreakdown';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Reservation ref:',
  },
  partyText: {
    id: `${scope}.party.text`,
    defaultMessage: 'Your party: {adultsCount, plural, =0 {0 adults} one {1 adult} other {{adultsCount} adults}}{childrenCount, plural, =0 {} one {, 1 child} other {, {childrenCount} children}}{infantsCount, plural, =0 {} one {, 1 infant} other {, {infantsCount} infants}}{petsCount, plural, =0 {} one {, 1 pet} other {, {petsCount} pets}}'
  },
});
