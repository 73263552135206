import React from 'react';
import LocaleLink from '@rentivo/gatsby-core/src/components/navigation/LocaleLink';
import { useLocation } from '@reach/router';
import { selectTarget } from '@rentivo/gatsby-core/src/selectors/paths';

const PropertyLinkComponent = ({property, forceNewTab, itemProp, className, path, queryParamsString, target, children}) => {
  const { pathname } = useLocation();
  const prevUrl = (queryParamsString) ? pathname + '?' + queryParamsString : pathname;
  return <LocaleLink
    className={className}
    to={path}
    itemProp={itemProp}
    target={forceNewTab !== undefined ? selectTarget(forceNewTab) : target}
    queryParamsString={queryParamsString}
    state={{property, prevUrl}}
  >
    {children}
  </LocaleLink>;
};

export default PropertyLinkComponent;
