import { defineMessages } from 'react-intl';

export const scope = 'app.components.forms.CustomerInfoForm';

export default defineMessages({
  contactInfoTitle: {
    id: `${scope}.contactInfoTitle`,
    defaultMessage: 'Your contact details',
  },
  addressTitle: {
    id: `${scope}.addressTitle`,
    defaultMessage: 'Your address',
  },
  messageTitle: {
    id: `${scope}.messageTitle`,
    defaultMessage: 'Message to the owner',
  },
  inputFirstNameLabel: {
    id: `${scope}.input.firstName.label`,
    defaultMessage: 'First name',
  },
  inputFirstNameErrorRequired: {
    id: `${scope}.input.firstName.error.required`,
    defaultMessage: 'First name is required',
  },
  inputLastNameLabel: {
    id: `${scope}.input.lastName.label`,
    defaultMessage: 'Last name',
  },
  inputLastNameErrorRequired: {
    id: `${scope}.input.lastName.error.required`,
    defaultMessage: 'Last name is required',
  },
  inputEmailLabel: {
    id: `${scope}.input.email.label`,
    defaultMessage: 'Email',
  },
  inputEmailErrorRequired: {
    id: `${scope}.input.email.error.required`,
    defaultMessage: 'Email is required',
  },
  inputEmailErrorValid: {
    id: `${scope}.input.email.error.valid`,
    defaultMessage: 'Please enter a valid email',
  },
  inputPhoneLabel: {
    id: `${scope}.input.phone.label`,
    defaultMessage: 'Phone (inc. extension)',
  },
  inputPhoneErrorRequired: {
    id: `${scope}.input.phone.error.required`,
    defaultMessage: 'Phone number (inc. extension) is required',
  },
  inputAddressLineOneLabel: {
    id: `${scope}.input.addressLineOne.label`,
    defaultMessage: 'Address line 1',
  },
  inputAddressLineOneErrorRequired: {
    id: `${scope}.input.addressLineOne.error.required`,
    defaultMessage: 'Address line 1 is required',
  },
  inputAddressLineTwoLabel: {
    id: `${scope}.input.addressLineTwo.label`,
    defaultMessage: 'Address line 2',
  },
  inputAddressLineTwoErrorRequired: {
    id: `${scope}.input.addressLineTwo.error.required`,
    defaultMessage: 'Second line of your address is required',
  },
  inputCityLabel: {
    id: `${scope}.input.city.label`,
    defaultMessage: 'Town / city',
  },
  inputCityErrorRequired: {
    id: `${scope}.input.city.error.required`,
    defaultMessage: 'Town / city is required',
  },
  inputStateLabel: {
    id: `${scope}.input.state.label`,
    defaultMessage: 'State / county',
  },
  inputStateErrorRequired: {
    id: `${scope}.input.state.error.required`,
    defaultMessage: 'State / county is required',
  },
  inputZipLabel: {
    id: `${scope}.input.zip.label`,
    defaultMessage: 'Zip / postcode',
  },
  inputZipErrorRequired: {
    id: `${scope}.input.zip.error.required`,
    defaultMessage: 'Zip / postcode is required',
  },
  inputCountryLabel: {
    id: `${scope}.input.country.label`,
    defaultMessage: 'Country',
  },
  inputCountryErrorRequired: {
    id: `${scope}.input.country.error.required`,
    defaultMessage: 'Country is required',
  },
  inputGuestMessageLabel: {
    id: `${scope}.input.guestMessage.label`,
    defaultMessage: 'Your message to the owner',
  },
  inputGuestMessageErrorRequired: {
    id: `${scope}.input.guestMessage.error.required`,
    defaultMessage: 'Please enter a message',
  },
  inputGuestAdultCountLabel: {
    id: `${scope}.input.guestAdultCount.label`,
    defaultMessage: 'Adults',
  },
  inputGuestAdultCountRequired: {
    id: `${scope}.input.guestAdultCount.error.required`,
    defaultMessage: 'Please enter the amount of adults',
  },
  inputGuestChildrenCountLabel: {
    id: `${scope}.input.guestChildrenCount.label`,
    defaultMessage: 'Children',
  },
  inputGuestChildrenCountRequired: {
    id: `${scope}.input.guestChildrenCount.error.required`,
    defaultMessage: 'Please enter the amount of children',
  },
  inputGuestInfantCountLabel: {
    id: `${scope}.input.guestInfantCount.label`,
    defaultMessage: 'Infants',
  },
  inputGuestInfantCountRequired: {
    id: `${scope}.input.guestInfantCount.error.required`,
    defaultMessage: 'Please enter the amount of infants',
  },
  inputPetsCountLabel: {
    id: `${scope}.input.petsCount.label`,
    defaultMessage: 'Pets',
  },
  inputPetsCountRequired: {
    id: `${scope}.input.petsCount.error.required`,
    defaultMessage: 'Please enter the amount of pets',
  },
  submitButtonText: {
    id: `${scope}.button.submit.text`,
    defaultMessage: 'Submit',
  },
});
