import { defineMessages } from 'react-intl';

export const scope = 'containers.CommerceProvider.Confirm.ConfirmRequestToBook';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Request to book',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'No payment required as this is a request to book property, once your booking criteria is checked we will contact you via email or phone to confirm the booking.',
  },
  confirmButtonLabel: {
    id: `${scope}.confirmButton.label`,
    defaultMessage: 'Request to book',
  }
});
