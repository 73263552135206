import React from 'react';
import {
  StyledDatesReview
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Review/components/DatesReview/styles';
import classNames from 'classnames';
import DatesReview from '@rentivo/gatsby-core/src/components/checkout/DatesReview';

const DatesReviewComponent = ({className, startDate, endDate, location, showTitle}) => {
  return <DatesReview className={className} startDate={startDate} endDate={endDate} location={location} showTitle={showTitle}/>
};

export default DatesReviewComponent;

