import React from 'react';
import CartBreakdown from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Confirm/components/CartBreakdown/component';
import { compose } from 'redux';
import withCart from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/withCart';

const enhance = compose(
  withCart(true)
);

export default enhance(CartBreakdown);
