import React from 'react';
import PropertySummary from '@rentivo/gatsby-core/src/components/property/PropertySummary';
import DatesReview from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Success/components/DatesReview';
import messages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Success/components/ReservationBreakdown/messages';
import {
  StyledReservationBreakdownBlocks,
  StyledReservationBreakdownWell
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Success/components/ReservationBreakdown/styles';
import { FormattedMessage } from 'react-intl';
import CartBreakdown from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Success/components/CartBreakdown';
import { Heading, Text, Box } from '@chakra-ui/react';

const ReservationBreakdownComponent = ({reservationId, adults, children, infants, pets, styleProps = {}}) => {
  return (
    <Box {...styleProps}>
      <StyledReservationBreakdownWell>
        <Heading as="h2" mb={1}><FormattedMessage {...messages.title}/> <small>#{reservationId}</small></Heading>
        <Text fontWeight="medium" mb={3} color="textLight"><FormattedMessage {...messages.partyText} values={{adultsCount: adults, childrenCount: children, infantsCount: infants, petsCount: pets}}/></Text>
        <StyledReservationBreakdownBlocks>
          <PropertySummary/>
          <DatesReview showTitle={false} />
        </StyledReservationBreakdownBlocks>
      </StyledReservationBreakdownWell>
      <CartBreakdown success={true} showTitle={false}/>
    </Box>
  )
};

export default ReservationBreakdownComponent;

