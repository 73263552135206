import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Review/components/DatesReview/messages';
import classNames from 'classnames';
import PropertyLink from '@rentivo/gatsby-core/src/components/property/PropertyLink';
import { faLongArrowLeft } from '@fortawesome/pro-regular-svg-icons/faLongArrowLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatesReview from '@rentivo/gatsby-core/src/components/checkout/DatesReview';
import { chakra } from '@chakra-ui/react';
import AwesomeIcon from '@rentivo/gatsby-core/src/components/ui/AwesomeIcon';

const DatesReviewComponent = ({className, startDate, endDate, checkInStartTime, checkInEndTime, checkOutTime, location}) => {

  return (
    <chakra.div className={className}>
      <DatesReview
        startDate={startDate}
        endDate={endDate}
        location={location}
        checkInStartTime={checkInStartTime}
        checkInStartTime={checkInStartTime}
        checkOutTime={checkOutTime}
      />
      <chakra.div fontSize="sm">
        <PropertyLink forceNewTab={false}>
          <AwesomeIcon icon={faLongArrowLeft} mr={2}/> <FormattedMessage {...messages.changeDatesLink} />
        </PropertyLink>
      </chakra.div>
    </chakra.div>
  );
};

export default DatesReviewComponent;
