import React from 'react';
import { Flex, Box, Heading, Text } from '@chakra-ui/react';

const Step = ({isCurrent, isLast, step, name, description}) => {
  return (
    <Flex
      overflow="hidden"
      flex={isLast ? 'none' : '1 1'}
      mr={isLast ? 0 : { base: 0, lg: 3 }}
      mb={isLast ? 0 : { base: 3, lg: 0 }}
    >
      <Flex
        mr={3}
        w="32px"
        h="32px"
        fontSize="lg"
        fontWeight="semibold"
        align="center"
        justify="center"
        border="1px solid"
        borderColor="primary.500"
        bg={isCurrent ? 'primary.500' : 'transparent'}
        color={isCurrent ? 'white' : 'primary.500'}
        borderRadius="50%"
      >
        {step}
      </Flex>
      <Box maxW="240px">
        <Heading
          as="h4"
          pr="16px"
          fontSize={{base: 'md', md: 'lg'}}
          position="relative"
          display="inline-block"
          _after={{
            position: 'absolute',
            top: '16px',
            left: '100%',
            display: 'block',
            w: '9999px',
            h: '1px',
            bg: 'gray.200',
            content: '""'
          }}
        >
          {name}
        </Heading>
        {description && <Text fontSize={{base: 'sm', md: 'md'}} pr={3} color="textLight">{description}</Text>}
      </Box>
    </Flex>
  );
};

const Steps = ({current = 0, steps = [], ...rest}) => {
  if(!steps.length) return null;

  return (
    <Flex {...rest} direction={{base: 'column', lg: 'row'}}>
      {steps.map((s, i) => <Step isLast={steps.length === (i + 1)} step={i + 1} isCurrent={current === i} key={i} {...s}/>)}
    </Flex>
  );

};

export default Steps;
