import React from 'react';
import { Heading, Text } from '@chakra-ui/react';
import StepLayout from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/StepLayout/component';
import { Button } from '@rentivo/gatsby-core/index';
import {
  StyledStepPageHeader,
  StyledStepPageBody
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/styles';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/NotFound/messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-regular-svg-icons/faLongArrowLeft';
import { routePathKeys } from '@rentivo/gatsby-core/src/components/navigation/RouteLink/constants';
import RouteLink from '@rentivo/gatsby-core/src/components/navigation/RouteLink';
import commerceMessages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/messages';

const NotFoundComponent = () => {
  return (
    <StepLayout valid={false} showStepsHeader={false}>
      <StyledStepPageHeader>
        <h1><FormattedMessage {...messages.title}/></h1>
        <p><FormattedMessage {...messages.description}/></p>
      </StyledStepPageHeader>

      <StyledStepPageBody>
        <RouteLink route={routePathKeys.ROUTE_BASE_SEARCH}>
          <Button type="primary"><FontAwesomeIcon icon={faLongArrowLeft} style={{marginRight: 7}}/> <FormattedMessage {...commerceMessages.backToSearchButtonLabel}/></Button>
        </RouteLink>
      </StyledStepPageBody>

    </StepLayout>
  )
};

export default NotFoundComponent;
