import { chakra, HStack } from '@chakra-ui/react';

export const StyledPaymentCards = chakra(HStack, {
  baseStyle: {
    fontSize: "xl",
    "> *": { marginLeft: 2 },
    ".fa-cc-visa": { color: "#1a1f71" },
    ".fa-cc-mastercard": { color: "#FF5F00" },
    ".fa-cc-amex": { color: "#6CC4EE" }
  }
});
