import { defineMessages } from 'react-intl';

export const scope = 'containers.CommerceProvider.Review.DatesReview';

export default defineMessages({
  changeDatesLink: {
    id: `${scope}.changeDates.link`,
    defaultMessage: 'Go back to change your dates',
  }
});
