import { defineMessages } from 'react-intl';

export const scope = 'app.components.forms.PaymentForm';

export default defineMessages({
  billingAddressTitle: {
    id: `${scope}.billingAddress.title`,
    defaultMessage: 'Billing address',
  },
  sameBillingAddressButtonLabel: {
    id: `${scope}.sameBillingAddressButton.label`,
    defaultMessage: 'Use same address',
  },
  differentBillingAddressButtonLabel: {
    id: `${scope}.differentBillingAddressButton.label`,
    defaultMessage: 'Use different billing address',
  },
  cardDetailsTitle: {
    id: `${scope}.cardDetails.title`,
    defaultMessage: 'Card details',
  },
  inputFullNameLabel: {
    id: `${scope}.input.fullName.label`,
    defaultMessage: 'Full name (as shown on card)',
  },
  inputFullNameErrorRequired: {
    id: `${scope}.input.fullName.error.required`,
    defaultMessage: 'Full name is required',
  },
  inputCardNumberLabel: {
    id: `${scope}.input.cardNumber.label`,
    defaultMessage: 'Long card number',
  },
  inputCardNumberErrorRequired: {
    id: `${scope}.input.cardNumber.error.required`,
    defaultMessage: 'Card is required',
  },
  inputCardNumberErrorValid: {
    id: `${scope}.input.cardNumber.error.valid`,
    defaultMessage: 'Please enter a valid card',
  },
  inputCardNumberErrorNotAccepted: {
    id: `${scope}.input.cardNumber.error.accepted`,
    defaultMessage: 'Sorry, we don\'t accept {card}',
  },
  inputCVCLabel: {
    id: `${scope}.input.CVC.label`,
    defaultMessage: 'CVV / Security',
  },
  inputCVCErrorRequired: {
    id: `${scope}.input.CVC.error.required`,
    defaultMessage: 'CVV / security is required',
  },
  inputCVCErrorValid: {
    id: `${scope}.input.CVC.error.valid`,
    defaultMessage: 'Please enter a valid CVV',
  },
  inputExpiryMonthLabel: {
    id: `${scope}.input.expiryMonth.label`,
    defaultMessage: 'Expiry month (MM)',
  },
  inputExpiryMonthErrorRequired: {
    id: `${scope}.input.expiryMonth.error.required`,
    defaultMessage: 'Expiry month is required',
  },
  inputFullNameErrorValid: {
    id: `${scope}.input.fullName.error.valid`,
    defaultMessage: 'Your name must be at least two words',
  },
  inputExpiryMonthErrorValid: {
    id: `${scope}.input.expiryMonth.error.valid`,
    defaultMessage: 'Please enter a valid expiry month (e.g 05)',
  },
  inputExpiryYearLabel: {
    id: `${scope}.input.expiryYear.label`,
    defaultMessage: 'Expiry year (YYYY)',
  },
  inputExpiryYearErrorRequired: {
    id: `${scope}.input.expiryYear.error.required`,
    defaultMessage: 'Expiry year is required',
  },
  inputExpiryYearErrorValid: {
    id: `${scope}.input.expiryYear.error.valid`,
    defaultMessage: 'Please enter a valid expiry year (e.g 2023)',
  },
  inputCardNumberErrorInvalid: {
    id: `${scope}.input.cardNumber.error.invalid`,
    defaultMessage: 'Please enter a valid card number',
  },
  inputCardNumberErrorUnsupported: {
    id: `${scope}.input.cardNumber.error.unsupported`,
    defaultMessage: 'Sorry we do not support {card}',
  },
  inputCvvErrorInvalid: {
    id: `${scope}.input.cvv.error.invalid`,
    defaultMessage: 'Please enter a valid CVV number',
  },
});
