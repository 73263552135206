import React from 'react';
import DatesReview from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Success/components/DatesReview/component';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import {
  selectPricingEndDateFromPricing,
  selectPricingStartDateFromPricing
} from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors';
import { selectPropertyAddressCity } from '@rentivo/gatsby-core/src/selectors/property';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import {
  makeSelectCommercePropertyAndPricingInData
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/selectors';

const mapPropertyToProps = createSelector(
  selectPropertyAddressCity,
  (city) => ({
    location: city
  })
);

const mapStateToProps = createSelector(
  makeSelectCommercePropertyAndPricingInData,
  ({ pricingInData }) => ({
      startDate: selectPricingStartDateFromPricing(pricingInData),
      endDate: selectPricingEndDateFromPricing(pricingInData)
   })
);

DatesReview.defaultProps = {
  className: '',
  showTitle: true
};

const enhance = compose(
  withProperty(mapPropertyToProps),
  connect(mapStateToProps)
);

export default enhance(DatesReview);
