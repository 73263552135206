import React from 'react';
import SuccessInstantBook from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Success/components/SuccessInstantBook/component';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import {
  selectCommerceData,
  selectCommerceReservationType
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/selectors';
import { connect } from 'react-redux';

const mapStateToProps = createSelector(
  selectCommerceReservationType,
  selectCommerceData,
  (reservationType, data) => {
    return {
      reservationType,
      data
    }
  }
);

const enhance = compose(
  connect(mapStateToProps)
);

export default enhance(SuccessInstantBook);
