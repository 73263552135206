import { defineMessages } from 'react-intl';

export const scope = 'containers.CommerceProvider.components';

export default defineMessages({
  nextButtonLabel: {
    id: `${scope}.nextButton.label`,
    defaultMessage: 'Continue'
  },
  prevButtonLabel: {
    id: `${scope}.prevButton.label`,
    defaultMessage: 'Previous'
  },
  backToSearchButtonLabel: {
    id: `${scope}.search.button.label`,
    defaultMessage: 'Back to search',
  }
});
