import React from 'react';
import DatesReview from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Review/components/DatesReview/component';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import {
  selectPricingEndDateFromPricing,
  selectPricingStartDateFromPricing
} from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors';
import {
  selectPropertyAddressCity,
  selectPropertyArrivalCheckInEndTime, selectPropertyArrivalCheckInStartTime, selectPropertyDepartureCheckOutTime
} from '@rentivo/gatsby-core/src/selectors/property';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import { makeSelectCommercePropertyAndPricingInCart } from '@rentivo/gatsby-core/src/containers/CommerceProvider/selectors';

const mapPropertyToProps = createSelector(
  selectPropertyAddressCity,
  selectPropertyArrivalCheckInStartTime,
  selectPropertyArrivalCheckInEndTime,
  selectPropertyDepartureCheckOutTime,
  (city, checkInStartTime, checkInEndTime, checkOutTime) => ({
    location: city,
    checkInStartTime,
    checkInEndTime,
    checkOutTime
  })
);

const mapStateToProps = createSelector(
  makeSelectCommercePropertyAndPricingInCart,
  ({ pricingInCart }) => ({
      startDate: selectPricingStartDateFromPricing(pricingInCart),
      endDate: selectPricingEndDateFromPricing(pricingInCart)
   })
);

DatesReview.defaultProps = {
  className: ''
};

const enhance = compose(
  withProperty(mapPropertyToProps),
  connect(mapStateToProps)
);

export default enhance(DatesReview);
