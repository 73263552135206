import { chakra } from '@chakra-ui/react';
import StepsHeader from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/StepsHeader';


export const StyledStepsHeader = chakra(StepsHeader, {
  baseStyle: {
    mb: 12
  }
});

export const StyledStepLayout = chakra('div', {
  baseStyle: {

  }
});

export const StyledStepLayoutContent = chakra('div', {
  baseStyle: {
    position: 'relative',
    boxShadow: { base: 0, md: 'lg' },
    borderRadius: 'lg',
    bg: { base: 'transparent', md: 'white' }
  }
});
