import { windowGlobal } from '@rentivo/gatsby-core/src/utils/window';
import { createMemorySource, createHistory } from '@reach/router';

let history = (windowGlobal) ? createHistory(windowGlobal) : null;

// for some types of tests you want a memory source
//let source = createMemorySource("/starting/url")
//let history = createHistory(source)

export default history;
