import { defineMessages } from 'react-intl';

export const scope = 'components.property.PropertyCancellation';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Cancellation policy',
  },
  freeCancellation: {
    id: `${scope}.retainedFree`,
    defaultMessage: 'Free cancellation',
  },
  noRefund: {
    id: `${scope}.noRefund`,
    defaultMessage: 'No refund',
  },
  someRefund: {
    id: `${scope}.someRefund`,
    defaultMessage: '{refundedPercentage}% guest refund',
  },
  refundRule: {
    id: `${scope}.refundRule`,
    defaultMessage: '<strong>{refund}</strong> if you cancel at least {daysBeforeArrival, plural, =0 {0 days} one {1 day} other {{daysBeforeArrival} days}} before check-in.',
  },
  refundRuleBetween: {
    id: `${scope}.refundRuleBetween`,
    defaultMessage: '<strong>{refund}</strong> if you cancel at between {prevDaysBeforeArrival} and {daysBeforeArrival} days before check-in',
  },
  refundRuleLessThanLast: {
    id: `${scope}.refundRuleLessThanLast`,
    defaultMessage: '<strong>{refund}</strong> if you cancel at less than {prevDaysBeforeArrival, plural, =0 {0 days} one {1 day} other {{prevDaysBeforeArrival} days}} before check-in.',
  },
  graceHoursMessage: {
    id: `${scope}.graceHoursMessage`,
    defaultMessage: '({graceHours} hour grace)',
  },
  refundRuleDate: {
    id: `${scope}.refundRuleDate`,
    defaultMessage: '<strong>{refund}</strong> if you cancel by {date}',
  },
  refundRuleBetweenDate: {
    id: `${scope}.refundRuleBetweenDate`,
    defaultMessage: '<strong>{refund}</strong> between {date} and {prevDate}',
  },
  refundRuleLessThanLastDate: {
    id: `${scope}.refundRuleLessThanLastDate`,
    defaultMessage: '<strong>{refund}</strong> if you cancel after {prevDate}',
  },
  daysBeforeCheckin: {
    id: `${scope}.daysBeforeCheckin`,
    defaultMessage: '{daysBeforeArrival, plural, =0 {0 days} one {1 day} other {{daysBeforeArrival} days}} before check-in',
  },
  checkin: {
    id: `${scope}.checkin`,
    defaultMessage: 'Check-in',
  }
});
