import { chakra } from '@chakra-ui/react';

export const StyledStepPageConfirmInstantBookFooterConversionWarning = chakra('div', {
  baseStyle: {
    overflow: "hidden",
    marginTop: 2,
    "> div": {
      width: { base: "100%", lg: "75%" },
      float: { base: "none", lg: "right" },
      fontSize: "sm",
      textAlign: { base: "left", lg: "right" },
    }
  }
});
