import React from 'react';
import StepLayout from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/StepLayout/component';
import { Button } from '@rentivo/gatsby-core/index';
import {
  StyledStepPageFooter,
  StyledStepPageHeader,
  StyledStepPageBody
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/styles';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Review/messages';
import commerceMessages from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/messages';


import {
  StyledCancellationPolicy,
  StyledDatesReview,
  StyledChangeGuests
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/components/Review/styles';
import { Heading, Text } from '@chakra-ui/react';

const ReviewComponent = ({ hasNextStep, hasPrevStep, nextStep, prevStep, isPricingLoading }) => {

  return (
    <StepLayout>
      <StyledStepPageHeader>
        <Heading as="h1"><FormattedMessage {...messages.title}/></Heading>
        <Text variant="light"><FormattedMessage {...messages.description}/></Text>
      </StyledStepPageHeader>

      <StyledStepPageBody>
        <StyledDatesReview/>
        <StyledChangeGuests/>
        <StyledCancellationPolicy/>
      </StyledStepPageBody>

      <StyledStepPageFooter>
        {hasPrevStep && <Button useDebounce disabled={isPricingLoading} onClick={() => prevStep()} className="prev"><FormattedMessage {...commerceMessages.prevButtonLabel}/></Button>}
        {hasNextStep && <Button useDebounce type="primary" disabled={isPricingLoading} onClick={() => nextStep()} className="next" ml="auto"><FormattedMessage {...commerceMessages.nextButtonLabel}/></Button>}
      </StyledStepPageFooter>
    </StepLayout>
  )
};

export default ReviewComponent;

