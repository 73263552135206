import { defineMessages } from 'react-intl';

export const scope = 'containers.CommerceProvider.Review.ChangeGuests';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Review your guests'
  }
});
